var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./lambdaisland.glogi.js");require("./lambdaisland.glogi.print.js");
'use strict';var Lz=function(){},Mz=function(a){return $CLJS.J.g($CLJS.Ay,a).value},xda=function(a){var b=Mz(a);return $CLJS.n(function(){var c=Mz($CLJS.Ry);return $CLJS.Rk.g?$CLJS.Rk.g(c,b):$CLJS.Rk.call(null,c,b)}())?$CLJS.wz:$CLJS.n(function(){var c=Mz($CLJS.Qy);return $CLJS.Rk.g?$CLJS.Rk.g(c,b):$CLJS.Rk.call(null,c,b)}())?$CLJS.Gz:$CLJS.n(function(){var c=Mz($CLJS.Oy);return $CLJS.Rk.g?$CLJS.Rk.g(c,b):$CLJS.Rk.call(null,c,b)}())?$CLJS.zz:$CLJS.n(function(){var c=Mz($CLJS.Iy);return $CLJS.Rk.g?
$CLJS.Rk.g(c,b):$CLJS.Rk.call(null,c,b)}())?$CLJS.Fz:$CLJS.n(function(){var c=Mz($CLJS.Py);return $CLJS.Rk.g?$CLJS.Rk.g(c,b):$CLJS.Rk.call(null,c,b)}())?$CLJS.Hz:$CLJS.n(function(){var c=Mz($CLJS.Ly);return $CLJS.Rk.g?$CLJS.Rk.g(c,b):$CLJS.Rk.call(null,c,b)}())?$CLJS.Cz:$CLJS.n(function(){var c=Mz($CLJS.Fy);return $CLJS.Rk.g?$CLJS.Rk.g(c,b):$CLJS.Rk.call(null,c,b)}())?$CLJS.Dz:$CLJS.Ez},Nz=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(b),$CLJS.p.h(" ")].join(""),
a],null)},Oz=function(a,b,c){var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.be.l(a,["color:",$CLJS.p.h($CLJS.J.g($CLJS.Kz,c))].join(""),$CLJS.H(["color:black"]))],null)},Pz=function(a,b,c){var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.be.l(a,["color:",$CLJS.p.h($CLJS.J.g($CLJS.Kz,$CLJS.Iz)),";background-color:",$CLJS.p.h($CLJS.J.g($CLJS.Kz,
c))].join(""),$CLJS.H(["color:black;background-color:inherit"]))],null)},yda=function(a){function b(c,d){return d>=c}a=Mz(a);if(b(Mz($CLJS.Ry),a))return"error";if(b(Mz($CLJS.Qy),a))return"warn";if(b(Mz($CLJS.Oy),a))return"info";b(Mz($CLJS.Iy),a);return"log"},Qz=function(a){return function(b){var c=$CLJS.O(b),d=$CLJS.J.g(c,$CLJS.Gy),e=$CLJS.J.g(c,$CLJS.Rt);b=$CLJS.J.g(c,$CLJS.Sy);e=yda(e);e=$CLJS.Ca(console,e);e=$CLJS.n(e)?e:console.log;$CLJS.Se.g(e,a.h?a.h(c):a.call(null,c));return $CLJS.n(b)?(c=
["[",$CLJS.p.h(d),"]"].join(""),d=$CLJS.p.h(b),b=b.stack,e.v?e.v(c,d,"\n",b):e.call(null,c,d,"\n",b)):null}},Rz=function Rz(a,b){for(;;){if($CLJS.E.g($CLJS.vz,b))return Oz(a,", ",$CLJS.Ez);if($CLJS.E.g($CLJS.xz,b))return Nz(a);if(b instanceof $CLJS.M)return Oz(a,b,$CLJS.zz);if(b instanceof $CLJS.r)return Oz(a,b,$CLJS.Fz);if("string"===typeof b)return Oz(a,$CLJS.Mh.l($CLJS.H([b])),$CLJS.yz);if($CLJS.zf(b)){var d=Nz(function(){var f=a,k=$CLJS.Eb(b);return Rz.g?Rz.g(f,k):Rz.call(null,f,k)}()),e=$CLJS.Fb(b);
return Rz.g?Rz.g(d,e):Rz.call(null,d,e)}if(b instanceof $CLJS.h||b instanceof $CLJS.Hg)return d=a,d=Oz(d,"{",$CLJS.Jz),d=$CLJS.eb(Rz,d,$CLJS.ff($CLJS.vz,b)),Oz(d,"}",$CLJS.Jz);if($CLJS.xd(b))return d=a,d=Oz(d,["#",$CLJS.p.h(function(){var f=$CLJS.ab(b),k=f.name;return $CLJS.td(k)?$CLJS.Mh.l($CLJS.H([f])):k}())," "].join(""),$CLJS.Az),d=Oz(d,"{",$CLJS.Jz),d=$CLJS.eb(Rz,d,$CLJS.ff($CLJS.vz,b)),Oz(d,"}",$CLJS.Jz);if($CLJS.vd(b))return d=a,d=Oz(d,"#{",$CLJS.Jz),d=$CLJS.eb(Rz,d,$CLJS.ff($CLJS.xz,b)),Oz(d,
"}",$CLJS.Jz);if($CLJS.zd(b))return d=a,d=Oz(d,"[",$CLJS.Jz),d=$CLJS.eb(Rz,d,$CLJS.ff($CLJS.xz,b)),Oz(d,"]",$CLJS.Jz);if(b instanceof $CLJS.Rf)d=Oz(a,"#queue ",$CLJS.Az),e=$CLJS.eg.g($CLJS.Cf,b),a=d,b=e;else{if($CLJS.Dd(b))return d=a,d=Oz(d,"(",$CLJS.Az),d=$CLJS.eb(Rz,d,$CLJS.ff($CLJS.xz,b)),Oz(d,")",$CLJS.Az);if(null!=b?b.I&16384||$CLJS.Bc===b.$i||(b.I?0:$CLJS.$a(Lz,b)):$CLJS.$a(Lz,b))d=Oz(a,"#atom ",$CLJS.Az),e=$CLJS.q(b),a=d,b=e;else if($CLJS.ei(b))d=Oz(a,"#uuid ",$CLJS.Az),e=$CLJS.p.h(b),a=d,
b=e;else if($CLJS.Xa(b))d=Oz(a,"#js ",$CLJS.Az),e=$CLJS.eb(function(f,k){return function(l,m){return $CLJS.R.j(l,$CLJS.zh.h(m),$CLJS.Ca(k,m))}}(a,b),$CLJS.N,Object.keys(b)),a=d,b=e;else if($CLJS.Ua(b))d=Oz(a,"#js ",$CLJS.Az),e=$CLJS.eg.g($CLJS.Cf,b),a=d,b=e;else return Oz(a,$CLJS.Mh.l($CLJS.H([b])),$CLJS.Bz)}}};$CLJS.zda=Qz(function(a){a=$CLJS.O(a);$CLJS.J.g(a,$CLJS.Rt);var b=$CLJS.J.g(a,$CLJS.Gy),c=$CLJS.J.g(a,$CLJS.Ti);$CLJS.J.g(a,$CLJS.Sy);return new $CLJS.P(null,2,5,$CLJS.Q,[["[",$CLJS.p.h(b),"]"].join(""),c],null)});
$CLJS.Ada=Qz(function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.Rt);a=$CLJS.J.g(b,$CLJS.Gy);var d=$CLJS.J.g(b,$CLJS.Ti);$CLJS.J.g(b,$CLJS.Sy);b=xda(c);d=Rz(Nz(Pz(Pz(Pz(new $CLJS.P(null,2,5,$CLJS.Q,["",$CLJS.Cf],null),"[",b),a,b),"]",b)),d);a=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.ee(a,d)});
$CLJS.Bda=Qz(function(a){a=$CLJS.O(a);$CLJS.J.g(a,$CLJS.Rt);var b=$CLJS.J.g(a,$CLJS.Gy),c=$CLJS.J.g(a,$CLJS.Ti);$CLJS.J.g(a,$CLJS.Sy);return new $CLJS.P(null,2,5,$CLJS.Q,[["[",$CLJS.p.h(b),"]"].join(""),$CLJS.Mh.l($CLJS.H([c]))],null)});