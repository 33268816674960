var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var g1,Fna,Gna,h1,i1;$CLJS.e1=function(a,b){return $CLJS.gc($CLJS.eb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.fc(c,d):c},$CLJS.ec($CLJS.Cf),b))};g1=function(a){return $CLJS.n(f1)?["(",$CLJS.p.h(a),")"].join(""):a};Fna=function(a,b,c){var d=$CLJS.NW(a,b);a=$CLJS.R0.j(a,b,d);var e=$CLJS.fB(c);return $CLJS.Re(function(f){return $CLJS.E.g($CLJS.fB($CLJS.T.h(f)),e)},a)};
Gna=function(a,b){var c=$CLJS.Zk.v(a,$CLJS.hQ,$CLJS.bE($CLJS.be,$CLJS.Cf),b);return $CLJS.zd($CLJS.cF.h(a))?$CLJS.Zk.v(c,$CLJS.cF,$CLJS.be,$CLJS.xW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wB,$CLJS.N,$CLJS.GW(b)],null))):c};h1=function(a,b,c){var d=$CLJS.GW(c);return $CLJS.R.l($CLJS.f0.j(a,b,c),$CLJS.YK,$CLJS.LZ,$CLJS.H([$CLJS.T,d,$CLJS.yE,d]))};i1=new $CLJS.M("metabase.lib.expression","infix-operator","metabase.lib.expression/infix-operator",246733613);
$CLJS.j1=new $CLJS.M("lib","source-uuid","lib/source-uuid",1828897581);var k1=function(){function a(d,e,f){var k=$CLJS.NW(d,e);k=$CLJS.EH($CLJS.Wk.g($CLJS.ch([f]),$CLJS.GW),$CLJS.hQ.h(k));if($CLJS.n(k))return k;throw $CLJS.hi($CLJS.tE("No expression named {0}",$CLJS.H([$CLJS.Mh.l($CLJS.H([f]))])),new $CLJS.h(null,3,[$CLJS.jS,f,$CLJS.QF,d,$CLJS.NL,e],null));}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();$CLJS.K0.m(null,$CLJS.wB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);c=k1.j(a,b,c);return $CLJS.L0.j(a,b,c)});$CLJS.M0.m(null,$CLJS.wB,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.I(c,2,null);return new $CLJS.h(null,7,[$CLJS.Ij,$CLJS.WK,$CLJS.j1,$CLJS.KE.h(d),$CLJS.T,e,$CLJS.CE,e,$CLJS.yE,$CLJS.g0.j(a,b,c),$CLJS.zB,$CLJS.L0.j(a,b,c),$CLJS.YK,$CLJS.LZ],null)});$CLJS.G0.m(null,$CLJS.qB,function(a,b,c){return $CLJS.p.h(c)});
$CLJS.G0.m(null,$CLJS.oB,function(a,b,c){return $CLJS.p.h(c)});$CLJS.G0.m(null,$CLJS.pB,function(a,b,c){return['"',$CLJS.p.h(c),'"'].join("")});$CLJS.G0.m(null,$CLJS.GB,function(a,b,c){return $CLJS.p.h(c)});$CLJS.G0.m(null,$CLJS.wB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});$CLJS.H0.m(null,$CLJS.wB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});
for(var f1=!1,Hna=new $CLJS.h(null,4,[$CLJS.ls,"+",$CLJS.st,"-",$CLJS.ms,"×",$CLJS.PG,"÷"],null),l1=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ls,$CLJS.st,$CLJS.PG,$CLJS.ms],null)),m1=null,n1=0,o1=0;;)if(o1<n1){var Ina=m1.X(null,o1);$CLJS.BF(Ina,i1);o1+=1}else{var p1=$CLJS.y(l1);if(p1){var q1=p1;if($CLJS.Ad(q1)){var r1=$CLJS.lc(q1),Jna=$CLJS.mc(q1),Kna=r1,Lna=$CLJS.D(r1);l1=Jna;m1=Kna;n1=Lna}else{var Mna=$CLJS.A(q1);$CLJS.BF(Mna,i1);l1=$CLJS.B(q1);m1=null;n1=0}o1=0}else break}
$CLJS.G0.m(null,i1,function(a,b,c){var d=$CLJS.y(c);c=$CLJS.A(d);d=$CLJS.B(d);$CLJS.A(d);d=$CLJS.B(d);c=$CLJS.J.g(Hna,c);a:{var e=f1;f1=!0;try{var f=$CLJS.Kt([" ",$CLJS.gh(c)," "].join(""),$CLJS.hf.g($CLJS.iu($CLJS.g0,a,b),d));break a}finally{f1=e}f=void 0}return g1(f)});$CLJS.H0.m(null,i1,function(){return"expression"});
$CLJS.K0.m(null,$CLJS.LH,function(a,b,c){c=$CLJS.y(c);$CLJS.A(c);c=$CLJS.B(c);$CLJS.A(c);var d=$CLJS.B(c);return $CLJS.Qd($CLJS.DF,function(){return function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v);x=$CLJS.L0.j(a,b,x);u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}u=$CLJS.A(l);return $CLJS.ee($CLJS.L0.j(a,
b,u),k($CLJS.Lc(l)))}return null}},null,null)}(d)}())});$CLJS.G0.m(null,$CLJS.sJ,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null),f=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.g0.v(a,b,e,d));b=$CLJS.p;d=b.h;c=$CLJS.Z0.g(f,c).toLowerCase();0<f?f=$CLJS.fa.j?$CLJS.fa.j("+ %d %s",f,c):$CLJS.fa.call(null,"+ %d %s",f,c):(f=$CLJS.GA(f),f=$CLJS.fa.j?$CLJS.fa.j("- %d %s",f,c):$CLJS.fa.call(null,"- %d %s",f,c));f=g1(f);return[a," ",d.call(b,f)].join("")});
$CLJS.H0.m(null,$CLJS.sJ,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.I0.j(a,b,d));b=$CLJS.p;d=b.h;c=$CLJS.Z0.g(e,c).toLowerCase();0<e?e=$CLJS.fa.j?$CLJS.fa.j("plus_%s_%s",e,c):$CLJS.fa.call(null,"plus_%s_%s",e,c):(e=$CLJS.GA(e),e=$CLJS.fa.j?$CLJS.fa.j("minus_%d_%s",e,c):$CLJS.fa.call(null,"minus_%d_%s",e,c));return[a,"_",d.call(b,e)].join("")});
$CLJS.G0.m(null,$CLJS.wG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.g0.v(a,b,e,d)});$CLJS.H0.m(null,$CLJS.wG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.I0.j(a,b,d)});
$CLJS.Nna=function(){function a(d,e,f,k){e=$CLJS.n(e)?e:-1;if($CLJS.n(Fna(d,e,f)))throw $CLJS.hi("Expression name conflicts with a column in the same query stage",new $CLJS.h(null,1,[$CLJS.jS,f],null));return $CLJS.YW.l(d,e,Gna,$CLJS.H([$CLJS.HW($CLJS.zW.h(k),f)]))}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);
};c.j=b;c.v=a;return c}();$CLJS.Ona=function(){function a(d,e){var f=$CLJS.Fe($CLJS.hQ.h($CLJS.NW(d,e)));return null==f?null:$CLJS.Yk.g($CLJS.iu(h1,d,e),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.s1=function(){function a(d,e){return $CLJS.Fe($CLJS.hQ.h($CLJS.NW(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$CLJS.wW.m(null,$CLJS.wB,function(a){return a});
$CLJS.Pna=function(){function a(d,e,f){var k=$CLJS.eg.g($CLJS.N,$CLJS.km(function(m,t){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GW(t),m],null)},$CLJS.s1.g(d,e))),l=$CLJS.NW(d,e);d=$CLJS.R0.j(d,e,l);return $CLJS.Fe($CLJS.e1(function(m){return $CLJS.Va(f)||$CLJS.Uk.g($CLJS.YK.h(m),$CLJS.LZ)||$CLJS.J.g(k,$CLJS.T.h(m))<f},d))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.Qna=function(){function a(d,e,f){return $CLJS.yW(h1(d,e,k1.j(d,e,f)))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();