var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.breakout.js");require("./metabase.lib.expression.js");require("./metabase.lib.field.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var nra,ora,z7,pra,qra,rra,sra,tra,ura,vra,A7,B7,C7,wra,D7;
nra=function(a){return function(b){var c=$CLJS.$e($CLJS.bh);return function(){function d(l,m){var t=a.h?a.h(m):a.call(null,m);if($CLJS.Id($CLJS.q(c),t))return l;c.Dd(null,$CLJS.be.g(c.Qb(null),t));return b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);
};k.o=f;k.h=e;k.g=d;return k}()}};ora=function(a,b,c){return $CLJS.eg.j($CLJS.Cf,$CLJS.sE(function(d){return $CLJS.R0.v(a,b,d,new $CLJS.h(null,2,[$CLJS.B0,c,$CLJS.v0,!1],null))}),$CLJS.MN.h($CLJS.NW(a,b)))};z7=function(a,b,c){return $CLJS.eg.j($CLJS.Cf,$CLJS.sE(function(d){return $CLJS.P0.v(a,b,d,c)}),$CLJS.MN.h($CLJS.NW(a,b)))};$CLJS.Y3=function(a,b){return $CLJS.eb(function(c,d){return $CLJS.YW.l(c,d,$CLJS.R,$CLJS.H([$CLJS.q7,$CLJS.P0.j(c,d,$CLJS.NW(c,d))]))},a,$CLJS.Bu(0,$CLJS.MW(a,b)))};
pra=function(a,b){a=$CLJS.NW(a,b);a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.Ij),d=$CLJS.J.g(a,$CLJS.WV);b=$CLJS.q7.h(a);if($CLJS.n(b))return b;var e=$CLJS.wV.h(a);if($CLJS.n(e)&&$CLJS.n(function(){var k=$CLJS.E.g(c,$CLJS.jW);return k?k:d}())){var f=function(){var k=c instanceof $CLJS.M?c.T:null;switch(k){case "mbql.stage/native":return $CLJS.$Z;case "mbql.stage/mbql":return $CLJS.UZ;default:throw Error(["No matching clause: ",$CLJS.p.h(k)].join(""));}}();return $CLJS.Fe(function(){return function m(l){return new $CLJS.ne(null,
function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.Ad(t)){var u=$CLJS.lc(t),v=$CLJS.D(u),x=$CLJS.qe(v);a:for(var z=0;;)if(z<v){var C=$CLJS.kd(u,z);C=$CLJS.nl.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.EZ,$CLJS.T.h(C),$CLJS.RZ,$CLJS.T.h(C)],null),C,new $CLJS.h(null,1,[$CLJS.YK,f],null)]));x.add(C);z+=1}else{u=!0;break a}return u?$CLJS.te($CLJS.ve(x),m($CLJS.mc(t))):$CLJS.te($CLJS.ve(x),null)}x=$CLJS.A(t);return $CLJS.ee($CLJS.nl.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.EZ,$CLJS.T.h(x),$CLJS.RZ,$CLJS.T.h(x)],
null),x,new $CLJS.h(null,1,[$CLJS.YK,f],null)])),m($CLJS.Lc(t)))}return null}},null,null)}($CLJS.KW.h(e))}())}return null};
qra=function(a,b,c){return $CLJS.Fe(function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.kd(l,v),z=t,C=$CLJS.R,G=C.l,K=x,S=$CLJS.HA($CLJS.EZ,$CLJS.T)(x);x=$CLJS.L1(a,x);x=c.h?c.h(x):c.call(null,x);C=G.call(C,K,$CLJS.YK,$CLJS.mL,$CLJS.H([$CLJS.EZ,S,$CLJS.RZ,x]));z.add(C);v+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.A(k);return $CLJS.ee($CLJS.R.l(u,$CLJS.YK,$CLJS.mL,$CLJS.H([$CLJS.EZ,$CLJS.HA($CLJS.EZ,$CLJS.T)(u),$CLJS.RZ,function(){var v=$CLJS.L1(a,u);return c.h?c.h(v):c.call(null,v)}()])),f($CLJS.Lc(k)))}return null}},null,null)}($CLJS.U5.g(a,b))}())};
rra=function(a,b,c){return $CLJS.Fe(function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.kd(l,v),z=t,C=$CLJS.R,G=C.l,K=x,S=$CLJS.T.h(x);x=$CLJS.T.h(x);x=c.h?c.h(x):c.call(null,x);C=G.call(C,K,$CLJS.YK,$CLJS.sL,$CLJS.H([$CLJS.EZ,S,$CLJS.RZ,x]));z.add(C);v+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),null)}var u=
$CLJS.A(k);return $CLJS.ee($CLJS.R.l(u,$CLJS.YK,$CLJS.sL,$CLJS.H([$CLJS.EZ,$CLJS.T.h(u),$CLJS.RZ,function(){var v=$CLJS.T.h(u);return c.h?c.h(v):c.call(null,v)}()])),f($CLJS.Lc(k)))}return null}},null,null)}($CLJS.G4.g(a,b))}())};
sra=function(a,b,c){var d=$CLJS.NW(a,b);if($CLJS.n(d)){d=$CLJS.O(d);var e=$CLJS.J.g(d,$CLJS.cF);return $CLJS.Fe(function(){return function l(k){return new $CLJS.ne(null,function(){for(;;){var m=$CLJS.y(k);if(m){if($CLJS.Ad(m)){var t=$CLJS.lc(m),u=$CLJS.D(t),v=$CLJS.qe(u);return function(){for(var K=0;;)if(K<u){var S=$CLJS.kd(t,K),V=$CLJS.I(S,0,null),Z=function(){var ra=V;ra=ra instanceof $CLJS.M?ra.T:null;switch(ra){case "field":return $CLJS.ZZ;case "expression":return $CLJS.LZ;default:throw Error(["No matching clause: ",
$CLJS.p.h(ra)].join(""));}}(),ha=$CLJS.f0.j(a,b,S);$CLJS.ue(v,$CLJS.R.l(ha,$CLJS.YK,Z,$CLJS.H([$CLJS.EZ,$CLJS.I0.j(a,b,ha),$CLJS.RZ,function(){var ra=$CLJS.L1(a,ha);return c.h?c.h(ra):c.call(null,ra)}()])));K+=1}else return!0}()?$CLJS.te($CLJS.ve(v),l($CLJS.mc(m))):$CLJS.te($CLJS.ve(v),null)}var x=$CLJS.A(m),z=$CLJS.I(x,0,null),C=function(){var K=z;K=K instanceof $CLJS.M?K.T:null;switch(K){case "field":return $CLJS.ZZ;case "expression":return $CLJS.LZ;default:throw Error(["No matching clause: ",$CLJS.p.h(K)].join(""));
}}(),G=$CLJS.f0.j(a,b,x);return $CLJS.ee($CLJS.R.l(G,$CLJS.YK,C,$CLJS.H([$CLJS.EZ,$CLJS.I0.j(a,b,G),$CLJS.RZ,function(){var K=$CLJS.L1(a,G);return c.h?c.h(K):c.call(null,K)}()])),l($CLJS.Lc(m)))}return null}},null,null)}(e)}())}return null};tra=function(a,b,c){return $CLJS.Fe($CLJS.eg.j($CLJS.Cf,$CLJS.sE(function(d){return d.j?d.j(a,b,c):d.call(null,a,b,c)}),new $CLJS.P(null,2,5,$CLJS.Q,[qra,rra],null)))};
ura=function(a,b,c){var d=$CLJS.g2(a,b);return $CLJS.n(d)?$CLJS.Fe(function(){return function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);return function(){for(var z=0;;)if(z<t){var C=$CLJS.kd(m,z);var G=$CLJS.HA($CLJS.RZ,$CLJS.EZ)(C);G=$CLJS.n(G)?G:$CLJS.I0.j(a,b,C);C=$CLJS.Ok.l($CLJS.nl.l($CLJS.H([C,new $CLJS.h(null,3,[$CLJS.YK,$CLJS.FZ,$CLJS.EZ,G,$CLJS.RZ,c.h?c.h(G):c.call(null,G)],null),$CLJS.n($CLJS.Q1.h(C))?
$CLJS.lm(C,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Q1],null)):null])),$CLJS.RW,$CLJS.H([$CLJS.CE]));u.add(C);z+=1}else return!0}()?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}var v=$CLJS.A(l),x=function(){var z=$CLJS.HA($CLJS.RZ,$CLJS.EZ)(v);return $CLJS.n(z)?z:$CLJS.I0.j(a,b,v)}();return $CLJS.ee($CLJS.Ok.l($CLJS.nl.l($CLJS.H([v,new $CLJS.h(null,3,[$CLJS.YK,$CLJS.FZ,$CLJS.EZ,x,$CLJS.RZ,c.h?c.h(x):c.call(null,x)],null),$CLJS.n($CLJS.Q1.h(v))?$CLJS.lm(v,new $CLJS.P(null,1,5,$CLJS.Q,
[$CLJS.Q1],null)):null])),$CLJS.RW,$CLJS.H([$CLJS.CE])),k($CLJS.Lc(l)))}return null}},null,null)}($CLJS.P0.j(a,d,$CLJS.NW(a,d)))}()):null};vra=function(a,b,c,d){return $CLJS.n(c)?(c=$CLJS.c0(a,c),$CLJS.n(c)?$CLJS.Fe($CLJS.R0.v(a,b,c,d)):null):null};
A7=function(a,b,c){return $CLJS.Fe(function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.kd(l,v),z=t;var C=$CLJS.zB.h(x);var G=$CLJS.R.l,K=x,S=$CLJS.T.h(x);x=$CLJS.T.h(x);x=c.h?c.h(x):c.call(null,x);C=$CLJS.e2(G.call($CLJS.R,K,$CLJS.YK,$CLJS.LZ,$CLJS.H([$CLJS.EZ,S,$CLJS.RZ,x])),$CLJS.Hi,$CLJS.n(C)?C:$CLJS.Ej);z.add(C);v+=1}else return!0}()?$CLJS.te($CLJS.ve(t),
f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),null)}var u=$CLJS.A(k);return $CLJS.ee(function(){var v=$CLJS.zB.h(u),x=$CLJS.R.l,z=u,C=$CLJS.T.h(u);var G=$CLJS.T.h(u);G=c.h?c.h(G):c.call(null,G);return $CLJS.e2(x.call($CLJS.R,z,$CLJS.YK,$CLJS.LZ,$CLJS.H([$CLJS.EZ,C,$CLJS.RZ,G])),$CLJS.Hi,$CLJS.n(v)?v:$CLJS.Ej)}(),f($CLJS.Lc(k)))}return null}},null,null)}($CLJS.Ona.g(a,b))}())};
B7=function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.B0);return $CLJS.Yk.g(function(f){return $CLJS.Ok.l(f,$CLJS.e_,$CLJS.H([$CLJS.RW,$CLJS.XW,$CLJS.jL]))},function(){var f=ura(a,b,e);if($CLJS.n(f))return f;f=$CLJS.NW(a,b);f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.nP),l=$CLJS.J.g(f,$CLJS.WV),m=$CLJS.n(k)?function(){var t=$CLJS.a0(a,k);return $CLJS.R0.v(a,b,t,d)}():null;if($CLJS.n(m))return m;l=$CLJS.n(l)?vra(a,b,l,$CLJS.R.j(d,$CLJS.v0,!1)):null;return $CLJS.n(l)?l:function v(u){return new $CLJS.ne(null,
function(){for(;;){var x=$CLJS.y(u);if(x){if($CLJS.Ad(x)){var z=$CLJS.lc(x),C=$CLJS.D(z),G=$CLJS.qe(C);return function(){for(var S=0;;)if(S<C){var V=$CLJS.kd(z,S),Z=G,ha=$CLJS.R,ra=ha.l,Na=V,zb=$CLJS.T.h(V);V=$CLJS.T.h(V);V=e.h?e.h(V):e.call(null,V);ha=ra.call(ha,Na,$CLJS.YK,$CLJS.$Z,$CLJS.H([$CLJS.EZ,zb,$CLJS.RZ,V]));Z.add(ha);S+=1}else return!0}()?$CLJS.te($CLJS.ve(G),v($CLJS.mc(x))):$CLJS.te($CLJS.ve(G),null)}var K=$CLJS.A(x);return $CLJS.ee($CLJS.R.l(K,$CLJS.YK,$CLJS.$Z,$CLJS.H([$CLJS.EZ,$CLJS.T.h(K),
$CLJS.RZ,function(){var S=$CLJS.T.h(K);return e.h?e.h(S):e.call(null,S)}()])),v($CLJS.Lc(x)))}return null}},null,null)}($CLJS.KW.h($CLJS.wV.h(f)))}())};
C7=function(a,b){var c=$CLJS.I(a,0,null);$CLJS.I(a,1,null);var d=$CLJS.I(a,2,null);switch(c instanceof $CLJS.M?c.T:null){case "field":if("number"===typeof d||"string"===typeof d)return $CLJS.E.g(d,$CLJS.cj.h(b));throw $CLJS.hi("unknown type of :field ref in lib.stage/ref-to?",new $CLJS.h(null,2,[$CLJS.iS,a,$CLJS.Wi,b],null));case "expression":return $CLJS.E.g(d,$CLJS.T.h(b));default:throw $CLJS.hi("unknown clause in lib.stage/ref-to?",new $CLJS.h(null,2,[$CLJS.iS,a,$CLJS.Wi,b],null));}};
wra=function(a,b,c){var d=$CLJS.hG.h($CLJS.NW(a,b));return $CLJS.n(d)?function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){var m=l;if($CLJS.Ad(m)){var t=$CLJS.lc(m),u=$CLJS.D(t),v=$CLJS.qe(u);return function(){for(var z=0;;)if(z<u){var C=$CLJS.kd(t,z);$CLJS.ue(v,function(){var G=$CLJS.EH(function(V,Z){return function(ha){return C7(ha,Z)}}(z,C,t,u,v,m,l,d,d),d);if($CLJS.n(G)){var K=$CLJS.m7(G);G=$CLJS.M2(G);G=$CLJS.O(G);G=$CLJS.J.g(G,$CLJS.si);var S=C;K=$CLJS.n(K)?$CLJS.t5(S,
K):S;return $CLJS.n(G)?$CLJS.E3(K,G):K}return C}());z+=1}else return!0}()?$CLJS.te($CLJS.ve(v),k($CLJS.mc(m))):$CLJS.te($CLJS.ve(v),null)}var x=$CLJS.A(m);return $CLJS.ee(function(){var z=$CLJS.EH(function(K){return function(S){return C7(S,K)}}(x,m,l,d,d),d);if($CLJS.n(z)){var C=$CLJS.m7(z);z=$CLJS.M2(z);z=$CLJS.O(z);z=$CLJS.J.g(z,$CLJS.si);var G=x;C=$CLJS.n(C)?$CLJS.t5(G,C):G;return $CLJS.n(z)?$CLJS.E3(C,z):C}return x}(),k($CLJS.Lc(m)))}return null}},null,null)}(c):c};
D7=new $CLJS.M("metabase.lib.stage","stage","metabase.lib.stage/stage",1448689281);$CLJS.spa={};$CLJS.BF($CLJS.cW,D7);$CLJS.BF($CLJS.jW,D7);$CLJS.w1.m(null,$CLJS.cW,function(a){return $CLJS.v1(a,new $CLJS.h(null,2,[$CLJS.iG,$CLJS.Ve($CLJS.Yk,$CLJS.w1),$CLJS.qW,$CLJS.Ve($CLJS.Yk,$CLJS.w1)],null))});$CLJS.M0.m(null,D7,function(){throw $CLJS.hi("You can't calculate a metadata map for a stage! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});
$CLJS.Q0.m(null,D7,function(a,b,c,d){d=$CLJS.O(d);c=$CLJS.J.g(d,$CLJS.B0);var e=$CLJS.J.g(d,$CLJS.v0);a=$CLJS.Y3(a,b);var f=$CLJS.O(d);var k=$CLJS.J.g(f,$CLJS.B0),l=$CLJS.J.g(f,$CLJS.z0),m=$CLJS.J.g(f,$CLJS.F0);f=$CLJS.gf.l(B7(a,b,f),$CLJS.n(m)?A7(a,b,k):null,$CLJS.H([$CLJS.n(l)?ora(a,b,k):null]));k=$CLJS.gf.g;d=$CLJS.n(e)?(e=$CLJS.Va($CLJS.WV.h($CLJS.NW(a,b))))?e:$CLJS.D0.h(d):e;return wra(a,b,k.call($CLJS.gf,f,$CLJS.n(d)?$CLJS.M1(a,b,f,c):null))});
$CLJS.O0.m(null,D7,function(a,b,c,d){c=$CLJS.O(d);d=$CLJS.J.g(c,$CLJS.B0);var e=pra(a,b);if($CLJS.n(e))return e;a=$CLJS.Y3(a,b);e=tra(a,b,d);var f=sra(a,b,d);return $CLJS.n(e)?$CLJS.eg.g(e,f):$CLJS.n(f)?($CLJS.mh(f),$CLJS.eg.j($CLJS.Cf,nra(function(k){return $CLJS.Ok.l(k,$CLJS.c_,$CLJS.H([$CLJS.YK,$CLJS.j1,$CLJS.RZ]))}),$CLJS.gf.g(f,z7(a,b,c)))):$CLJS.gf.l(B7(a,b,new $CLJS.h(null,2,[$CLJS.v0,!1,$CLJS.B0,d],null)),A7(a,b,d),$CLJS.H([z7(a,b,c)]))});$CLJS.G0.m(null,$CLJS.jW,function(){return $CLJS.VE("Native query")});
var E7=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nP,$CLJS.WV,$CLJS.MN],null),F7=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.iG,$CLJS.hG,$CLJS.qW,$CLJS.YR,$CLJS.uS],null);
$CLJS.G0.m(null,$CLJS.cW,function(a,b,c,d){var e=$CLJS.Y3(a,b);a=$CLJS.Fe(function(){var f=$CLJS.eg.j($CLJS.N,$CLJS.Wk.g($CLJS.zC,$CLJS.hf.h(function(l){return new $CLJS.P(null,2,5,$CLJS.Q,[l,$CLJS.kna.j(e,b,l)],null)})),new $CLJS.P(null,2,5,$CLJS.Q,[E7,F7],null)),k=$CLJS.Kt(" + ",$CLJS.Xk.g($CLJS.MA,$CLJS.hf.g(f,E7)));f=$CLJS.hf.g(f,F7);return $CLJS.Kt(", ",$CLJS.Xk.g($CLJS.MA,$CLJS.ee(k,f)))}());if($CLJS.n(a))return a;a=$CLJS.g2(e,b);return $CLJS.n(a)?$CLJS.g0.v(e,a,$CLJS.NW(e,a),d):null});