var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.schema.js");require("./metabase.util.malli.registry.js");
'use strict';var lV,mV,nV,pV,qV,rV,sV,tV,uV,vV;lV=new $CLJS.M("metabase.lib.schema.template-tag","widget-type","metabase.lib.schema.template-tag/widget-type",2021141560);mV=new $CLJS.M("metabase.lib.schema.template-tag","raw-value","metabase.lib.schema.template-tag/raw-value",-530604923);nV=new $CLJS.M("metabase.lib.schema.template-tag","template-tag","metabase.lib.schema.template-tag/template-tag",-1178540449);
$CLJS.oV=new $CLJS.M("metabase.lib.schema.template-tag","template-tag-map","metabase.lib.schema.template-tag/template-tag-map",1471181299);pV=new $CLJS.M("metabase.lib.schema.template-tag","type","metabase.lib.schema.template-tag/type",1926679295);qV=new $CLJS.M("metabase.lib.schema.template-tag","field-filter","metabase.lib.schema.template-tag/field-filter",1376344463);rV=new $CLJS.M("metabase.lib.schema.template-tag","snippet","metabase.lib.schema.template-tag/snippet",190689821);
sV=new $CLJS.M("metabase.lib.schema.template-tag","common","metabase.lib.schema.template-tag/common",1713198790);tV=new $CLJS.M("metabase.lib.schema.template-tag","value.common","metabase.lib.schema.template-tag/value.common",-974864959);uV=new $CLJS.M("metabase.lib.schema.template-tag","raw-value.type","metabase.lib.schema.template-tag/raw-value.type",-805651412);vV=new $CLJS.M("metabase.lib.schema.template-tag","source-query","metabase.lib.schema.template-tag/source-query",479361081);$CLJS.X(lV,$CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.h(null,1,[$CLJS.zt,"Valid template tag :widget-type"],null),$CLJS.mA],null),$CLJS.Xg($CLJS.gV)));$CLJS.X(pV,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Cs,$CLJS.PM,$CLJS.EN,$CLJS.NF,$CLJS.au,$CLJS.DB,$CLJS.KP],null));
$CLJS.X(sV,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.DE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yE,$CLJS.DE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cj,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,$CLJS.DE,$CLJS.ik],null)],null)],null));
$CLJS.X(tV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Us,!0],null),$CLJS.Kl],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fO,new $CLJS.h(null,1,[$CLJS.Us,!0],null),$CLJS.Qs],null)],null)],null));
$CLJS.X(qV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tV],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,$CLJS.NF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZN,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jj,new $CLJS.h(null,1,[$CLJS.Us,!0],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,$CLJS.ok],null)],null)],null)],null));
$CLJS.X(rV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sV],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,$CLJS.PM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BM,$CLJS.DE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bQ,new $CLJS.h(null,1,[$CLJS.Us,!0],null),$CLJS.pL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cQ,new $CLJS.h(null,1,[$CLJS.Us,!0],null),$CLJS.hL],null)],null)],null));
$CLJS.X(vV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,$CLJS.EN],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lR,$CLJS.gL],null)],null)],null));$CLJS.X(uV,$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Cs],null),$CLJS.VU));
$CLJS.X(mV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uV],null)],null)],null)],null));
$CLJS.X(nV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pV],null)],null)],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Js,new $CLJS.h(null,1,[$CLJS.Ri,$CLJS.qj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EN,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gp,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mV],null)],null)],null)],null));
$CLJS.X($CLJS.oV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zi,$CLJS.DE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,1,[$CLJS.zt,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Qe(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.T.h(b))},a)}],null)],null));