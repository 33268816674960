var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var cfa,dfa,efa,ffa,gfa,hfa,ifa,$G,jfa,kfa,lfa,mfa,nfa,ofa,pfa,qfa,bH,rfa;$CLJS.VG=new $CLJS.M(null,"supported-field","supported-field",-2061545519);cfa=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.WG=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);dfa=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);
efa=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.XG=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);$CLJS.YG=new $CLJS.M(null,"column-name","column-name",551523580);ffa=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.ZG=new $CLJS.M(null,"display-info","display-info",-816930907);gfa=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);
hfa=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);ifa=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);$G=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);jfa=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);kfa=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);lfa=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);
mfa=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);nfa=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);ofa=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);pfa=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.aH=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);qfa=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);
bH=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);$CLJS.cH=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);rfa=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.KF($CLJS.Zx,$CLJS.H([$CLJS.st,$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wB,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ns,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null)],null)],null)]));$CLJS.KF($CLJS.uG,$CLJS.H([$CLJS.st,$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wB,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ns,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null)],null)],null)]));
$CLJS.IF($CLJS.nG,$CLJS.H([$CLJS.st,$CLJS.XD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null)],null)]));$CLJS.IF($CLJS.ZF,$CLJS.H([$CLJS.st,$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null)]));$CLJS.IF($CLJS.CG,$CLJS.H([$CLJS.st,$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)]));
$CLJS.IF($CLJS.pk,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null)],null)]));$CLJS.BF($CLJS.pk,$CLJS.nF);$CLJS.IF($CLJS.HG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null)],null)]));$CLJS.BF($CLJS.HG,$CLJS.nF);$CLJS.IF($CLJS.go,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null)],null)]));$CLJS.BF($CLJS.go,$CLJS.nF);
$CLJS.X($G,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.h(null,1,[$CLJS.zt,"valid percentile"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,1,[$CLJS.zt,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.IF($CLJS.eG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$G],null)]));$CLJS.BF($CLJS.eG,$CLJS.nF);
$CLJS.IF($CLJS.AG,$CLJS.H([$CLJS.st,$CLJS.XD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)]));$CLJS.IF($CLJS.JG,$CLJS.H([$CLJS.st,$CLJS.XD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null)],null)]));$CLJS.IF($CLJS.GG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null)],null)]));
$CLJS.IF($CLJS.QG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null)],null)]));$CLJS.BF($CLJS.GG,$CLJS.nF);$CLJS.BF($CLJS.QG,$CLJS.nF);$CLJS.IF($CLJS.OG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)]));$CLJS.BF($CLJS.OG,$CLJS.nF);
$CLJS.IF($CLJS.$F,$CLJS.H([$CLJS.st,$CLJS.XD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null)],null)]));$CLJS.X(bH,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.Fs,qfa,ofa,hfa,dfa,cfa,gfa,ifa,jfa,kfa,nfa,lfa,rfa,pfa,efa,mfa,$CLJS.Kl],null));$CLJS.X($CLJS.WG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.h(null,1,[$CLJS.go,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bH],null)],null));
$CLJS.dH=new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.mG,$CLJS.Zx,$CLJS.XG,!1,$CLJS.aH,$CLJS.OF,$CLJS.ZG,function(){return new $CLJS.h(null,3,[$CLJS.yE,$CLJS.VE("Count of rows"),$CLJS.YG,$CLJS.VE("Count"),$CLJS.Tt,$CLJS.VE("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.mG,$CLJS.GG,$CLJS.VG,$CLJS.lk,$CLJS.XG,!0,$CLJS.aH,$CLJS.OF,$CLJS.ZG,function(){return new $CLJS.h(null,3,[$CLJS.yE,$CLJS.VE("Sum of ..."),$CLJS.YG,$CLJS.VE("Sum"),$CLJS.Tt,$CLJS.VE("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.mG,$CLJS.nG,$CLJS.VG,$CLJS.lk,$CLJS.XG,!0,$CLJS.aH,$CLJS.OF,$CLJS.ZG,function(){return new $CLJS.h(null,3,[$CLJS.yE,$CLJS.VE("Average of ..."),$CLJS.YG,$CLJS.VE("Average"),$CLJS.Tt,$CLJS.VE("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.mG,$CLJS.HG,$CLJS.VG,$CLJS.lk,$CLJS.XG,!0,$CLJS.aH,$CLJS.pG,$CLJS.ZG,function(){return new $CLJS.h(null,3,[$CLJS.yE,$CLJS.VE("Median of ..."),$CLJS.YG,$CLJS.VE("Median"),$CLJS.Tt,$CLJS.VE("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.mG,$CLJS.ZF,$CLJS.VG,$CLJS.li,$CLJS.XG,!0,$CLJS.aH,$CLJS.OF,$CLJS.ZG,function(){return new $CLJS.h(null,3,[$CLJS.yE,$CLJS.VE("Number of distinct values of ..."),$CLJS.YG,$CLJS.VE("Distinct values"),$CLJS.Tt,$CLJS.VE("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.mG,$CLJS.QG,$CLJS.VG,$CLJS.lk,$CLJS.XG,!0,$CLJS.aH,$CLJS.OF,$CLJS.ZG,function(){return new $CLJS.h(null,3,[$CLJS.yE,$CLJS.VE("Cumulative sum of ..."),
$CLJS.YG,$CLJS.VE("Sum"),$CLJS.Tt,$CLJS.VE("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.mG,$CLJS.uG,$CLJS.XG,!1,$CLJS.aH,$CLJS.OF,$CLJS.ZG,function(){return new $CLJS.h(null,3,[$CLJS.yE,$CLJS.VE("Cumulative count of rows"),$CLJS.YG,$CLJS.VE("Count"),$CLJS.Tt,$CLJS.VE("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.mG,$CLJS.JG,$CLJS.VG,$CLJS.lk,$CLJS.XG,
!0,$CLJS.aH,$CLJS.DG,$CLJS.ZG,function(){return new $CLJS.h(null,3,[$CLJS.yE,$CLJS.VE("Standard deviation of ..."),$CLJS.YG,$CLJS.VE("SD"),$CLJS.Tt,$CLJS.VE("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.mG,$CLJS.go,$CLJS.VG,$CLJS.bj,$CLJS.XG,!0,$CLJS.aH,$CLJS.OF,$CLJS.ZG,function(){return new $CLJS.h(null,3,[$CLJS.yE,$CLJS.VE("Minimum of ..."),$CLJS.YG,$CLJS.VE("Min"),$CLJS.Tt,$CLJS.VE("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.mG,$CLJS.pk,$CLJS.VG,$CLJS.bj,$CLJS.XG,!0,$CLJS.aH,$CLJS.OF,$CLJS.ZG,function(){return new $CLJS.h(null,3,[$CLJS.yE,$CLJS.VE("Maximum of ..."),$CLJS.YG,$CLJS.VE("Max"),$CLJS.Tt,$CLJS.VE("Maximum value of a column")],null)}],null)],null);
$CLJS.X(ffa,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ij,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,$CLJS.cH],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mG,$CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Cs],null),$CLJS.hf.h($CLJS.mG),$CLJS.dH)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VG,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,$CLJS.aj],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XG,$CLJS.Qs],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.aH,$CLJS.aj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZG,$CLJS.md],null)],null));