var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var l7,n7,o7,dra,era,fra,r7,s7,u7,v7,gra,w7,hra,ira;l7=function(a){var b=new $CLJS.h(null,3,[$CLJS.KE,$CLJS.p.h($CLJS.UE()),$CLJS.zB,$CLJS.zB.h(a),$CLJS.Hi,$CLJS.HA($CLJS.Hi,$CLJS.zB)(a)],null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wB,b,$CLJS.HA($CLJS.CE,$CLJS.T)(a)],null)};$CLJS.m7=function(a){return $CLJS.y5.h(a)};n7=function(a){return $CLJS.R.j(a,$CLJS.Ij,$CLJS.P4)};
o7=function(a,b){return $CLJS.E.g($CLJS.xE.h(a),$CLJS.lm(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OE,$CLJS.wE,$CLJS.NE],null)))};dra=function(a){return $CLJS.v1(a,new $CLJS.h(null,1,[$CLJS.OE,$CLJS.zh],null))};
era=function(a,b){var c=$CLJS.Re(function(e){return $CLJS.EH(function(f){return $CLJS.E.g($CLJS.J.g(f,e),a)},b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RZ,$CLJS.T],null));if($CLJS.n(c))return c;c=$CLJS.RA($CLJS.My);if($CLJS.n($CLJS.QA("metabase.lib.field",c))){var d=$CLJS.tE("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.H([$CLJS.Mh.l($CLJS.H([a])),$CLJS.Mh.l($CLJS.H([$CLJS.Yk.g($CLJS.RZ,b)]))]));d instanceof Error?$CLJS.PA("metabase.lib.field",c,$CLJS.Iw(),d):$CLJS.PA("metabase.lib.field",
c,$CLJS.Iw.l($CLJS.H([d])),null)}return null};
fra=function(a,b,c){if($CLJS.n(p7))return null;var d=p7;p7=!0;try{var e=$CLJS.g2(a,b),f=$CLJS.n(e)?$CLJS.NW(a,e):$CLJS.NW(a,b),k=function(){var m=$CLJS.q7.h(f);if($CLJS.n(m))return m;m=$CLJS.IA(f,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wV,$CLJS.KW],null));if($CLJS.n(m))return m;m=$CLJS.n(function(){var u=$CLJS.WV.h(f);if($CLJS.n(u))return u;u=$CLJS.nP.h(f);if($CLJS.n(u))return u;u=$CLJS.hQ.h(f);return $CLJS.n(u)?u:$CLJS.cF.h(f)}())?$CLJS.R0.j(a,b,f):null;if($CLJS.n(m))return m;m=$CLJS.RA($CLJS.My);if($CLJS.n($CLJS.QA("metabase.lib.field",
m))){var t=$CLJS.tE("Cannot resolve column {0}: stage has no metadata",$CLJS.H([$CLJS.Mh.l($CLJS.H([c]))]));return t instanceof Error?$CLJS.PA("metabase.lib.field",m,$CLJS.Iw(),t):$CLJS.PA("metabase.lib.field",m,$CLJS.Iw.l($CLJS.H([t])),null)}return null}(),l=function(){var m=$CLJS.y(k);return m?era(c,k):m}();return $CLJS.n(l)?$CLJS.n(e)?$CLJS.R.j($CLJS.R.j($CLJS.H3($CLJS.Ok.l(l,$CLJS.cj,$CLJS.H([$CLJS.kL,$CLJS.XW,$CLJS.RW])),null),$CLJS.T,function(){var m=$CLJS.RZ.h(l);return $CLJS.n(m)?m:$CLJS.T.h(l)}()),
$CLJS.YK,$CLJS.FZ):l:null}finally{p7=d}};
r7=function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.CP);var f=$CLJS.I(c,2,null);c=$CLJS.nl.l($CLJS.H([function(){var k=$CLJS.zB.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.zB,k],null):null}(),function(){var k=$CLJS.HA($CLJS.Hi,$CLJS.zB)(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.Hi,k],null):null}(),function(){var k=$CLJS.DR.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.XW,k],null):null}(),function(){var k=$CLJS.zG.h(e);return $CLJS.n(k)?new $CLJS.h(null,
1,[$CLJS.RW,k],null):null}(),$CLJS.Fd(f)?function(){var k=$CLJS.l2(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Ij,$CLJS.WK,$CLJS.T,f],null)}():$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.Ij,$CLJS.WK,$CLJS.T,f],null):function(){var k=fra(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Ij,$CLJS.WK,$CLJS.T,f],null)}()]));return $CLJS.n(d)?$CLJS.H3(c,d):c};s7=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.RW);return $CLJS.n($CLJS.n(b)?$CLJS.Id($CLJS.BH,b):b)?$CLJS.Aj:$CLJS.HA($CLJS.Hi,$CLJS.zB)(a)};
$CLJS.t7=function(a,b,c,d){$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null),f=$CLJS.O(e),k=$CLJS.J.g(f,$CLJS.KE),l=$CLJS.J.g(f,$CLJS.zB),m=$CLJS.J.g(f,$CLJS.DR),t=$CLJS.J.g(f,$CLJS.Hi);e=$CLJS.J.g(f,$CLJS.CP);var u=$CLJS.J.g(f,$CLJS.NN),v=$CLJS.J.g(f,$CLJS.zG),x=$CLJS.nl.l;k=new $CLJS.h(null,2,[$CLJS.Ij,$CLJS.WK,$CLJS.j1,k],null);f=$CLJS.yE.h(f);a=$CLJS.n(f)?f:$CLJS.g0.j(a,b,d);c=x.call($CLJS.nl,$CLJS.H([k,c,new $CLJS.h(null,1,[$CLJS.yE,a],null)]));c=$CLJS.n(t)?$CLJS.R.j(c,$CLJS.Hi,t):c;l=$CLJS.n(l)?$CLJS.R.j(c,
$CLJS.zB,l):c;v=$CLJS.n(v)?$CLJS.R.j(l,$CLJS.RW,v):l;m=$CLJS.n(m)?$CLJS.R.j(v,$CLJS.XW,m):v;u=$CLJS.n(u)?$CLJS.R.j(m,$CLJS.jL,u):m;return $CLJS.n(e)?$CLJS.H3(u,e):u};u7=function(a,b,c){return $CLJS.n($CLJS.Re(function(d){return $CLJS.E.g($CLJS.si.h(d),c)},a))?$CLJS.Yk.g(function(d){var e=$CLJS.Id(d,b)?$CLJS.Ok.l(d,d,$CLJS.H([b])):d;return $CLJS.E.g($CLJS.si.h(d),c)?$CLJS.R.j(e,b,!0):e},a):a};
v7=function(a){var b=$CLJS.n($CLJS.Q1.h(a))?null:function(){var d=$CLJS.YK.h(a),e=new $CLJS.ah(null,new $CLJS.h(null,3,[$CLJS.UZ,null,$CLJS.$Z,null,$CLJS.FZ,null],null),null);return e.h?e.h(d):e.call(null,d)}(),c=$CLJS.nl.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.KE,$CLJS.p.h($CLJS.UE()),$CLJS.zB,$CLJS.zB.h(a),$CLJS.Hi,s7(a)],null),function(){var d=$CLJS.J1(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.CP,d],null):null}(),function(){var d=$CLJS.RW.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.zG,d],
null):null}(),function(){var d=$CLJS.VW.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.VW,d],null):null}(),function(){var d=$CLJS.XW.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.DR,d],null):null}(),function(){var d=$CLJS.jL.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.NN,d],null):null}()]));b=($CLJS.n(b)?$CLJS.HA($CLJS.RZ,$CLJS.T):$CLJS.HA($CLJS.cj,$CLJS.T))(a);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tG,c,b],null)};
gra=function(a,b){return $CLJS.lf(function(c){return $CLJS.E.g($CLJS.YK.h(c),$CLJS.LZ)},$CLJS.R0.v(a,b,$CLJS.NW(a,b),new $CLJS.h(null,3,[$CLJS.z0,!1,$CLJS.F0,!0,$CLJS.v0,!1],null)))};w7=new $CLJS.M(null,"parent-id","parent-id",-1400729131);$CLJS.q7=new $CLJS.M("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);hra=new $CLJS.M(null,"earliest","earliest",-1928154382);ira=new $CLJS.M(null,"latest","latest",24323665);var lra;$CLJS.w1.m(null,$CLJS.tG,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zh.h(b),$CLJS.v1(c,new $CLJS.h(null,2,[$CLJS.zG,$CLJS.zh,$CLJS.DR,dra],null)),a],null)});var p7=!1,x7=function x7(a,b){var d=$CLJS.C1(a,w7.h(b));a=$CLJS.n(w7.h(d))?x7.g?x7.g(a,d):x7.call(null,a,d):d;a=$CLJS.O(a);var e=$CLJS.J.g(a,$CLJS.T);return $CLJS.Zk.j(b,$CLJS.T,function(f){return[$CLJS.p.h(e),".",$CLJS.p.h(f)].join("")})};
$CLJS.K0.m(null,$CLJS.WK,function(a,b,c){return s7(c)});$CLJS.K0.m(null,$CLJS.tG,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.zG);$CLJS.I(c,2,null);c=r7(a,b,c);c=$CLJS.n(d)?$CLJS.R.j(c,$CLJS.RW,d):c;return $CLJS.L0.j(a,b,c)});$CLJS.M0.m(null,$CLJS.WK,function(a,b,c){a=$CLJS.O(c);b=$CLJS.J.g(a,$CLJS.T);return $CLJS.R.j(a,$CLJS.T,b)});$CLJS.M0.m(null,$CLJS.tG,function(a,b,c){var d=r7(a,b,c);b=$CLJS.t7(a,b,d,c);return $CLJS.n(w7.h(b))?x7(a,b):b});
$CLJS.G0.m(null,$CLJS.WK,function(a,b,c,d){c=$CLJS.O(c);var e=$CLJS.J.g(c,$CLJS.yE),f=$CLJS.J.g(c,$CLJS.T),k=$CLJS.J.g(c,$CLJS.si),l=$CLJS.J.g(c,$CLJS.XW),m=$CLJS.J.g(c,$CLJS.c_),t=$CLJS.J.g(c,$CLJS.jL),u=$CLJS.J.g(c,$CLJS.kL);e=$CLJS.n(e)?e:"string"===typeof f?$CLJS.H1.g($CLJS.EB,f):$CLJS.p.h(f);$CLJS.E.g(d,$CLJS.h0)&&-1==e.indexOf(" → ")?($CLJS.n(t)?(t=$CLJS.C1(a,t),$CLJS.n(t)?a=(0,$CLJS.ma)($CLJS.LA($CLJS.yE.h($CLJS.j0.j(a,b,t)),$CLJS.ZW,"")):(u=$CLJS.I1(a,u),a=$CLJS.g0.v(a,b,u,d))):a=null,m=$CLJS.n(a)?
a:$CLJS.n(m)?m:$CLJS.J1(c)):m=null;m=$CLJS.n(m)?[$CLJS.p.h(m)," → ",$CLJS.p.h(e)].join(""):e;return $CLJS.n(k)?(c=$CLJS.hB($CLJS.LA($CLJS.gh(k),"-"," ")),$CLJS.fa.j?$CLJS.fa.j("%s: %s",m,c):$CLJS.fa.call(null,"%s: %s",m,c)):$CLJS.n(l)?(c=$CLJS.v5(l,c),$CLJS.fa.j?$CLJS.fa.j("%s: %s",m,c):$CLJS.fa.call(null,"%s: %s",m,c)):m});
$CLJS.G0.m(null,$CLJS.tG,function(a,b,c,d){$CLJS.I(c,0,null);var e=$CLJS.I(c,1,null),f=$CLJS.O(e);e=$CLJS.J.g(f,$CLJS.DR);var k=$CLJS.J.g(f,$CLJS.CP),l=$CLJS.J.g(f,$CLJS.zG);f=$CLJS.J.g(f,$CLJS.NN);$CLJS.I(c,2,null);c=r7(a,b,c);c=$CLJS.n(k)?$CLJS.R.j(c,$CLJS.c_,k):c;l=$CLJS.n(l)?$CLJS.R.j(c,$CLJS.si,l):c;e=$CLJS.n(e)?$CLJS.R.j(l,$CLJS.XW,e):l;e=$CLJS.n(f)?$CLJS.R.j(e,$CLJS.jL,f):e;return $CLJS.n(e)?$CLJS.g0.v(a,b,e,d):$CLJS.VE("[Unknown Field]")});
$CLJS.H0.m(null,$CLJS.WK,function(a,b,c){a=$CLJS.O(c);return $CLJS.J.g(a,$CLJS.T)});$CLJS.H0.m(null,$CLJS.tG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=r7(a,b,c);return $CLJS.n(c)?$CLJS.I0.j(a,b,c):"unknown_field"});
$CLJS.N0.m(null,$CLJS.WK,function(a,b,c){return $CLJS.nl.l($CLJS.H([function(){var d=$CLJS.xI($CLJS.N0,$CLJS.ci);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),$CLJS.E.g($CLJS.YK.h(c),$CLJS.UZ)?function(){var d=$CLJS.b_.h(c);return $CLJS.n(d)?(d=$CLJS.c0(a,d),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.WW,new $CLJS.h(null,2,[$CLJS.T,$CLJS.T.h(d),$CLJS.yE,$CLJS.T.h(d)],null)],null):null):null}():null]))});$CLJS.a1.m(null,$CLJS.tG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);return $CLJS.zG.h(b)});
$CLJS.a1.m(null,$CLJS.WK,function(a){return $CLJS.RW.h(a)});
$CLJS.$0.m(null,$CLJS.tG,function(a,b){$CLJS.I(a,0,null);var c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(b)){var d=$CLJS.Id($CLJS.BH,b),e=$CLJS.yC($CLJS.VW,$CLJS.Hi,$CLJS.zB)(c);c=$CLJS.R.l(c,$CLJS.zG,b,$CLJS.H([$CLJS.Hi,d?$CLJS.Aj:e,$CLJS.VW,e]));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tG,c,a],null)}b=$CLJS.VW.h(c);c=$CLJS.n(b)?$CLJS.Ok.g($CLJS.R.j(c,$CLJS.Hi,b),$CLJS.VW):c;c=$CLJS.Ok.g(c,$CLJS.zG);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tG,c,a],null)});
$CLJS.$0.m(null,$CLJS.WK,function(a,b){return $CLJS.n(b)?$CLJS.R.l(a,$CLJS.RW,b,$CLJS.H([$CLJS.VW,$CLJS.yC($CLJS.VW,$CLJS.Hi,$CLJS.zB)(a)])):$CLJS.Ok.l(a,$CLJS.RW,$CLJS.H([$CLJS.VW]))});$CLJS.c1.m(null,$CLJS.tG,function(a,b,c){return $CLJS.d1.j(a,b,r7(a,b,c))});
$CLJS.c1.m(null,$CLJS.WK,function(a,b,c){if($CLJS.Uk.g($CLJS.YK.h(c),$CLJS.LZ)){a=$CLJS.HA($CLJS.Hi,$CLJS.zB)(c);b=null==c?null:$CLJS.aO.h(c);if(null==b)var d=null;else try{var e=$CLJS.AD.h($CLJS.qj.h(b));if($CLJS.n(e)){var f=$CLJS.O(e),k=$CLJS.J.g(f,hra),l=$CLJS.J.g(f,ira),m=$CLJS.wna.l($CLJS.H([$CLJS.Y0.h(k),$CLJS.Y0.h(l)]));d=isNaN(m)?null:$CLJS.n($CLJS.ds.g?$CLJS.ds.g(1,m):$CLJS.ds.call(null,1,m))?$CLJS.Xi:$CLJS.n($CLJS.ds.g?$CLJS.ds.g(31,m):$CLJS.ds.call(null,31,m))?$CLJS.gu:$CLJS.n($CLJS.ds.g?
$CLJS.ds.g(365,m):$CLJS.ds.call(null,365,m))?$CLJS.sj:$CLJS.Bj}else d=null}catch(t){if(t instanceof Error)d=null;else throw t;}e=$CLJS.AC(a,$CLJS.AD)?$CLJS.Ena:$CLJS.AC(a,$CLJS.aD)?$CLJS.Dna:$CLJS.AC(a,$CLJS.$D)?$CLJS.Cna:$CLJS.Cf;d=$CLJS.n(d)?u7(e,$CLJS.ci,d):e;return $CLJS.n($CLJS.RW.h(c))?u7(d,$CLJS.o0,$CLJS.RW.h(c)):d}return $CLJS.Cf});
$CLJS.y5.m(null,$CLJS.tG,function(a){var b=null==a?null:$CLJS.RE(a);b=null==b?null:$CLJS.DR.h(b);return null==b?null:$CLJS.R.l(b,$CLJS.Ij,$CLJS.x5,$CLJS.H([$CLJS.w5,function(c,d){return r7(c,d,a)}]))});$CLJS.y5.m(null,$CLJS.WK,function(a){var b=null==a?null:$CLJS.XW.h(a);return null==b?null:$CLJS.R.l(b,$CLJS.Ij,$CLJS.x5,$CLJS.H([$CLJS.w5,$CLJS.Ue(a)]))});$CLJS.s5.m(null,$CLJS.tG,function(a,b){return $CLJS.SE(a,$CLJS.QE,$CLJS.H([$CLJS.DR,b]))});
$CLJS.s5.m(null,$CLJS.WK,function(a,b){return $CLJS.QE(a,$CLJS.XW,b)});$CLJS.z5.m(null,$CLJS.tG,function(a,b,c){return $CLJS.A5.j(a,b,r7(a,b,c))});
$CLJS.z5.m(null,$CLJS.WK,function(a,b,c){b=$CLJS.O(c);c=$CLJS.J.g(b,$CLJS.Hi);var d=$CLJS.J.g(b,$CLJS.aO),e=$CLJS.J.g(b,$CLJS.ak);if($CLJS.Uk.g($CLJS.YK.h(b),$CLJS.LZ)){var f=function(){var m=null==a?null:$CLJS.A1($CLJS.$_(a));m=null==m?null:$CLJS.nA.h(m);return null==m?null:$CLJS.Id(m,$CLJS.DR)}(),k=$CLJS.IA(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qj,$CLJS.Uj],null)),l=$CLJS.m7(b);return function u(t){return new $CLJS.ne(null,function(){for(;;){var v=$CLJS.y(t);if(v){if($CLJS.Ad(v)){var x=$CLJS.lc(v),
z=$CLJS.D(x),C=$CLJS.qe(z);return function(){for(var K=0;;)if(K<z){var S=$CLJS.kd(x,K),V=C,Z=S;S=o7(S,l)?$CLJS.R.j(Z,$CLJS.o0,!0):Z;V.add(S);K+=1}else return!0}()?$CLJS.te($CLJS.ve(C),u($CLJS.mc(v))):$CLJS.te($CLJS.ve(C),null)}var G=$CLJS.A(v);return $CLJS.ee(function(){var K=G;return o7(G,l)?$CLJS.R.j(K,$CLJS.o0,!0):K}(),u($CLJS.Lc(v)))}return null}},null,null)}($CLJS.Va(function(){if($CLJS.n(f)){var t=$CLJS.go.h(k);return $CLJS.n(t)?$CLJS.pk.h(k):t}return f}())?null:$CLJS.AC(e,$CLJS.Cj)?$CLJS.Yk.g(n7,
new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.u5(),new $CLJS.h(null,2,[$CLJS.yE,$CLJS.VE("Bin every 0.1 degrees"),$CLJS.xE,new $CLJS.h(null,2,[$CLJS.OE,$CLJS.NE,$CLJS.NE,.1],null)],null),new $CLJS.h(null,2,[$CLJS.yE,$CLJS.VE("Bin every 1 degree"),$CLJS.xE,new $CLJS.h(null,2,[$CLJS.OE,$CLJS.NE,$CLJS.NE,1],null)],null),new $CLJS.h(null,2,[$CLJS.yE,$CLJS.VE("Bin every 10 degrees"),$CLJS.xE,new $CLJS.h(null,2,[$CLJS.OE,$CLJS.NE,$CLJS.NE,10],null)],null),new $CLJS.h(null,2,[$CLJS.yE,$CLJS.VE("Bin every 20 degrees"),
$CLJS.xE,new $CLJS.h(null,2,[$CLJS.OE,$CLJS.NE,$CLJS.NE,20],null)],null)],null)):$CLJS.AC(c,$CLJS.Uj)&&!$CLJS.AC(e,$CLJS.ni)?$CLJS.Yk.g(n7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.u5(),new $CLJS.h(null,2,[$CLJS.yE,$CLJS.VE("10 bins"),$CLJS.xE,new $CLJS.h(null,2,[$CLJS.OE,$CLJS.wE,$CLJS.wE,10],null)],null),new $CLJS.h(null,2,[$CLJS.yE,$CLJS.VE("50 bins"),$CLJS.xE,new $CLJS.h(null,2,[$CLJS.OE,$CLJS.wE,$CLJS.wE,50],null)],null),new $CLJS.h(null,2,[$CLJS.yE,$CLJS.VE("100 bins"),$CLJS.xE,new $CLJS.h(null,2,
[$CLJS.OE,$CLJS.wE,$CLJS.wE,100],null)],null)],null)):null)}return $CLJS.Cf});$CLJS.wW.m(null,$CLJS.tG,function(a){return a});
$CLJS.wW.m(null,$CLJS.WK,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.YK);switch(b instanceof $CLJS.M?b.T:null){case "source/aggregations":return b=new $CLJS.h(null,2,[$CLJS.KE,$CLJS.p.h($CLJS.UE()),$CLJS.Hi,$CLJS.HA($CLJS.Hi,$CLJS.zB)(a)],null),a=$CLJS.j1.h(a),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iG,b,a],null);case "source/expressions":return l7(a);case "source/fields":case "source/breakouts":return $CLJS.n($CLJS.CE.h(a))?l7(a):v7(a);default:return v7(a)}});
$CLJS.jra=function(){function a(e,f,k){k=$CLJS.Fe($CLJS.Yk.g($CLJS.yW,k));var l=gra(e,f),m=$CLJS.eg.j($CLJS.bh,$CLJS.dm(function(t){return $CLJS.r2.v(e,f,t,l)}),$CLJS.n(k)?k:$CLJS.Cf);m=$CLJS.Xk.g(m,l);k=$CLJS.n(k)?$CLJS.eg.j(k,$CLJS.hf.h($CLJS.yW),m):null;return $CLJS.YW.l(e,f,$CLJS.QE,$CLJS.H([$CLJS.cF,k]))}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return function(f,k){return d.j?d.j(f,k,e):d.call(null,f,k,e)}}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,
e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();$CLJS.y7=function(){function a(d,e){return $CLJS.cF.h($CLJS.NW(d,e))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.kra=function(){function a(d,e){var f=$CLJS.R0.v(d,e,$CLJS.NW(d,e),new $CLJS.h(null,3,[$CLJS.z0,!1,$CLJS.F0,!1,$CLJS.v0,!1],null)),k=$CLJS.y7.g(d,e);return $CLJS.td(k)?$CLJS.Yk.g(function(l){return $CLJS.R.j(l,$CLJS.SZ,!0)},f):$CLJS.F3(d,e,f,k)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
lra=function(){function a(d,e,f){var k=$CLJS.NW(d,e),l=$CLJS.E.g($CLJS.mC(f),$CLJS.iG)?$CLJS.P0:$CLJS.R0;k=l.j?l.j(d,e,k):l.call(null,d,e,k);return $CLJS.r2.v(d,e,f,k)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.mra=function(){function a(d,e,f){f=$CLJS.BZ.j(d,e,f);return lra.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();