var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var Uea,AE,EE,PE;
$CLJS.rE=function(a,b){return function(){function c(l,m,t){return $CLJS.Ed(function(){var u=a.h?a.h(l):a.call(null,l);return $CLJS.n(u)?(u=a.h?a.h(m):a.call(null,m),$CLJS.n(u)?(u=a.h?a.h(t):a.call(null,t),$CLJS.n(u)?(u=b.h?b.h(l):b.call(null,l),$CLJS.n(u)?(u=b.h?b.h(m):b.call(null,m),$CLJS.n(u)?b.h?b.h(t):b.call(null,t):u):u):u):u):u}())}function d(l,m){return $CLJS.Ed(function(){var t=a.h?a.h(l):a.call(null,l);return $CLJS.n(t)?(t=a.h?a.h(m):a.call(null,m),$CLJS.n(t)?(t=b.h?b.h(l):b.call(null,l),
$CLJS.n(t)?b.h?b.h(m):b.call(null,m):t):t):t}())}function e(l){var m=a.h?a.h(l):a.call(null,l);l=$CLJS.n(m)?b.h?b.h(l):b.call(null,l):m;return $CLJS.Ed(l)}var f=null,k=function(){function l(t,u,v,x){var z=null;if(3<arguments.length){z=0;for(var C=Array(arguments.length-3);z<C.length;)C[z]=arguments[z+3],++z;z=new $CLJS.w(C,0,null)}return m.call(this,t,u,v,z)}function m(t,u,v,x){return $CLJS.Ed(function(){var z=f.j(t,u,v);return $CLJS.n(z)?$CLJS.Qe(function(C){var G=a.h?a.h(C):a.call(null,C);return $CLJS.n(G)?
b.h?b.h(C):b.call(null,C):G},x):z}())}l.A=3;l.B=function(t){var u=$CLJS.A(t);t=$CLJS.B(t);var v=$CLJS.A(t);t=$CLJS.B(t);var x=$CLJS.A(t);t=$CLJS.Lc(t);return m(u,v,x,t)};l.l=m;return l}();f=function(l,m,t,u){switch(arguments.length){case 0:return!0;case 1:return e.call(this,l);case 2:return d.call(this,l,m);case 3:return c.call(this,l,m,t);default:var v=null;if(3<arguments.length){v=0;for(var x=Array(arguments.length-3);v<x.length;)x[v]=arguments[v+3],++v;v=new $CLJS.w(x,0,null)}return k.l(l,m,t,
v)}throw Error("Invalid arity: "+arguments.length);};f.A=3;f.B=k.B;f.o=function(){return!0};f.h=e;f.g=d;f.j=c;f.l=k.l;return f}()};$CLJS.sE=function(a){return $CLJS.Wk.g($CLJS.hf.h(a),$CLJS.zC)};$CLJS.tE=function(a,b){a=$CLJS.Zt($CLJS.LA(a,/''/,"'"),/\{\d+\}/);return $CLJS.Se.j($CLJS.Et.t,$CLJS.rl(a),$CLJS.rl(b))};$CLJS.uE=new $CLJS.M("lib","metadata","lib/metadata",1798917220);$CLJS.vE=new $CLJS.M("metabase.lib.schema.common","positive-int","metabase.lib.schema.common/positive-int",-733946956);
$CLJS.wE=new $CLJS.M(null,"num-bins","num-bins",847672055);$CLJS.xE=new $CLJS.M(null,"mbql","mbql",69346710);$CLJS.yE=new $CLJS.M(null,"display-name","display-name",694513143);Uea=new $CLJS.M("metabase.lib.schema.common","external-op","metabase.lib.schema.common/external-op",1918684841);$CLJS.zE=new $CLJS.M("metabase.lib.schema.common","semantic-or-relation-type","metabase.lib.schema.common/semantic-or-relation-type",-158604052);
AE=new $CLJS.M("metabase.lib.schema.common","relation-type","metabase.lib.schema.common/relation-type",1412287664);$CLJS.BE=new $CLJS.M("lib","external-op","lib/external-op",-1470923877);$CLJS.CE=new $CLJS.M("lib","expression-name","lib/expression-name",-1799326590);$CLJS.DE=new $CLJS.M("metabase.lib.schema.common","non-blank-string","metabase.lib.schema.common/non-blank-string",-2032366619);EE=new $CLJS.M("metabase.lib.schema.common","semantic-type","metabase.lib.schema.common/semantic-type",-1623124128);
$CLJS.FE=new $CLJS.M(null,"database-type","database-type",-426840562);$CLJS.GE=new $CLJS.M(null,"operator","operator",-1860875338);$CLJS.HE=new $CLJS.M("metabase.lib.schema.common","int-greater-than-or-equal-to-zero","metabase.lib.schema.common/int-greater-than-or-equal-to-zero",-1646435681);$CLJS.IE=new $CLJS.M("metabase.lib.schema.common","options","metabase.lib.schema.common/options",-1078359294);
$CLJS.JE=new $CLJS.M("metabase.lib.schema.common","positive-number","metabase.lib.schema.common/positive-number",2061626826);$CLJS.KE=new $CLJS.M("lib","uuid","lib/uuid",-2145250720);$CLJS.LE=new $CLJS.M(null,"x","x",2099068185);$CLJS.ME=new $CLJS.M("metabase.lib.schema.common","base-type","metabase.lib.schema.common/base-type",999524206);$CLJS.NE=new $CLJS.M(null,"bin-width","bin-width",1377922579);$CLJS.OE=new $CLJS.M(null,"strategy","strategy",-1471631918);
PE=new $CLJS.M("metabase.lib.schema.common","uuid","metabase.lib.schema.common/uuid",1172938098);$CLJS.X($CLJS.DE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ek,new $CLJS.h(null,1,[$CLJS.go,1],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,1,[$CLJS.zt,"non-blank string"],null),$CLJS.Te($CLJS.MA)],null)],null));$CLJS.X($CLJS.HE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yi,new $CLJS.h(null,1,[$CLJS.go,0],null)],null));$CLJS.X($CLJS.vE,$CLJS.Wl);
$CLJS.X($CLJS.JE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,1,[$CLJS.zt,"positive number"],null),$CLJS.rE($CLJS.Il,$CLJS.am)],null));$CLJS.X(PE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ek,new $CLJS.h(null,2,[$CLJS.go,36,$CLJS.pk,36],null)],null));
$CLJS.X(EE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,2,[$CLJS.zt,"valid semantic type",$CLJS.Bt,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Hj);return["Not a valid semantic type: ",$CLJS.Mh.l($CLJS.H([a]))].join("")}],null),function(a){return $CLJS.AC(a,$CLJS.wj)}],null));
$CLJS.X(AE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,2,[$CLJS.zt,"valid relation type",$CLJS.Bt,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Hj);return["Not a valid relation type: ",$CLJS.Mh.l($CLJS.H([a]))].join("")}],null),function(a){return $CLJS.AC(a,$CLJS.ni)}],null));$CLJS.X($CLJS.zE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AE],null)],null));
$CLJS.X($CLJS.ME,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,2,[$CLJS.zt,"valid base type",$CLJS.Bt,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Hj);return["Not a valid base type: ",$CLJS.Mh.l($CLJS.H([a]))].join("")}],null),function(a){return $CLJS.AC(a,$CLJS.Ej)}],null));
$CLJS.X($CLJS.IE,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KE,PE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zB,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,$CLJS.ME],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hi,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,$CLJS.ME],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ak,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,
$CLJS.zE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FE,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,$CLJS.DE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,$CLJS.DE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yE,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,$CLJS.DE],null)],null)],null));
$CLJS.X(Uea,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ij,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,$CLJS.BE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,$CLJS.ek,$CLJS.aj],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.et,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,$CLJS.li],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jj,new $CLJS.h(null,1,[$CLJS.Us,!0],null),$CLJS.IE],null)],null));