var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.internal.time_common.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var YI,$I,bJ,nJ,rJ,zJ;$CLJS.XI=new $CLJS.M(null,"get-month","get-month",-369374731);YI=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);$CLJS.ZI=new $CLJS.M(null,"convert-timezone","convert-timezone",-124803001);$I=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
$CLJS.aJ=new $CLJS.M(null,"iso","iso",-1366207543);bJ=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.cJ=new $CLJS.M(null,"get-second","get-second",-2065946318);$CLJS.dJ=new $CLJS.M(null,"get-year","get-year",-936011274);$CLJS.eJ=new $CLJS.M(null,"absolute-datetime","absolute-datetime",-560340465);
$CLJS.fJ=new $CLJS.M("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);$CLJS.gJ=new $CLJS.M(null,"week-of-year-iso","week-of-year-iso",-1827744293);$CLJS.hJ=new $CLJS.M(null,"datetime-diff","datetime-diff",1133112155);$CLJS.iJ=new $CLJS.M(null,"interval","interval",1708495417);$CLJS.jJ=new $CLJS.M(null,"us","us",746429226);$CLJS.kJ=new $CLJS.M(null,"relative-datetime","relative-datetime",1285169278);
$CLJS.lJ=new $CLJS.M(null,"datetime","datetime",494675702);$CLJS.mJ=new $CLJS.M(null,"now","now",-1650525531);nJ=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);$CLJS.oJ=new $CLJS.M(null,"instance","instance",-2121349050);$CLJS.pJ=new $CLJS.M(null,"get-day","get-day",127568857);$CLJS.qJ=new $CLJS.M(null,"get-week","get-week",-888059349);
rJ=new $CLJS.M("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);$CLJS.sJ=new $CLJS.M(null,"datetime-add","datetime-add",209603411);$CLJS.tJ=new $CLJS.M(null,"get-minute","get-minute",1468086343);$CLJS.uJ=new $CLJS.M(null,"get-day-of-week","get-day-of-week",2010140752);$CLJS.vJ=new $CLJS.M("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);
$CLJS.wJ=new $CLJS.M(null,"temporal-extract","temporal-extract",1781686405);$CLJS.xJ=new $CLJS.M(null,"get-hour","get-hour",622714059);$CLJS.yJ=new $CLJS.M(null,"datetime-subtract","datetime-subtract",275307697);zJ=new $CLJS.M("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.AJ=new $CLJS.M(null,"get-quarter","get-quarter",1327778593);$CLJS.BJ=new $CLJS.M(null,"week-of-year-us","week-of-year-us",849423511);
$CLJS.CJ=new $CLJS.M(null,"week-of-year-instance","week-of-year-instance",2047974624);$CLJS.DJ=new $CLJS.M(null,"target","target",253001721);$CLJS.IF($CLJS.iJ,$CLJS.H([$CLJS.st,$CLJS.lD,$CLJS.yi,$CLJS.pE]));$CLJS.XE.m(null,$CLJS.fJ,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.YE(a);return $CLJS.vd(a)?(a=$CLJS.ou.g(a,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.aD,null,$CLJS.AD,null],null),null)),$CLJS.E.g($CLJS.D(a),1)?$CLJS.A(a):a):a});$CLJS.BF($CLJS.fJ,$CLJS.nF);
for(var EJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sJ,$CLJS.yJ],null)),FJ=null,GJ=0,HJ=0;;)if(HJ<GJ){var IJ=FJ.X(null,HJ);$CLJS.IF(IJ,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fF],null),$CLJS.yi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pE],null)]));$CLJS.BF(IJ,$CLJS.fJ);HJ+=1}else{var JJ=$CLJS.y(EJ);if(JJ){var KJ=JJ;if($CLJS.Ad(KJ)){var LJ=$CLJS.lc(KJ),Kga=$CLJS.mc(KJ),Lga=LJ,Mga=$CLJS.D(LJ);EJ=Kga;FJ=Lga;GJ=Mga}else{var MJ=$CLJS.A(KJ);$CLJS.IF(MJ,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.fF],null),$CLJS.yi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pE],null)]));$CLJS.BF(MJ,$CLJS.fJ);EJ=$CLJS.B(KJ);FJ=null;GJ=0}HJ=0}else break}
for(var NJ=$CLJS.y(new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.dJ,$CLJS.XI,$CLJS.pJ,$CLJS.xJ,$CLJS.tJ,$CLJS.cJ,$CLJS.AJ],null)),OJ=null,PJ=0,QJ=0;;)if(QJ<PJ){var Nga=OJ.X(null,QJ);$CLJS.IF(Nga,$CLJS.H([$CLJS.st,$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fF],null)],null)]));QJ+=1}else{var RJ=$CLJS.y(NJ);if(RJ){var SJ=RJ;if($CLJS.Ad(SJ)){var TJ=$CLJS.lc(SJ),Oga=$CLJS.mc(SJ),Pga=TJ,Qga=$CLJS.D(TJ);NJ=Oga;OJ=Pga;PJ=Qga}else{var Rga=$CLJS.A(SJ);$CLJS.IF(Rga,$CLJS.H([$CLJS.st,
$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fF],null)],null)]));NJ=$CLJS.B(SJ);OJ=null;PJ=0}QJ=0}else break}$CLJS.IF($CLJS.hJ,$CLJS.H([$CLJS.st,$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lH],null)]));
for(var UJ=$CLJS.y(new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.AJ,null,$CLJS.tJ,null,$CLJS.xJ,null,$CLJS.uJ,null,$CLJS.cJ,null,$CLJS.XI,null,$CLJS.dJ,null,$CLJS.pJ,null],null),null)),VJ=null,WJ=0,XJ=0;;)if(XJ<WJ){var Sga=VJ.X(null,XJ);$CLJS.IF(Sga,$CLJS.H([$CLJS.st,$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fF],null)],null)]));XJ+=1}else{var YJ=$CLJS.y(UJ);if(YJ){var ZJ=YJ;if($CLJS.Ad(ZJ)){var $J=$CLJS.lc(ZJ),Tga=$CLJS.mc(ZJ),Uga=$J,Vga=$CLJS.D($J);
UJ=Tga;VJ=Uga;WJ=Vga}else{var Wga=$CLJS.A(ZJ);$CLJS.IF(Wga,$CLJS.H([$CLJS.st,$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fF],null)],null)]));UJ=$CLJS.B(ZJ);VJ=null;WJ=0}XJ=0}else break}$CLJS.X(zJ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Cs,$CLJS.aJ,$CLJS.jJ,$CLJS.oJ],null));
$CLJS.KF($CLJS.qJ,$CLJS.H([$CLJS.st,$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ns,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zJ],null)],null)],null)],null)]));
$CLJS.X($CLJS.vJ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,$CLJS.DE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.h(null,2,[$CLJS.zt,"valid timezone ID",$CLJS.Bt,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Hj);return["invalid timezone ID: ",$CLJS.Mh.l($CLJS.H([a]))].join("")}],null)],null),$CLJS.fH($CLJS.Jd,$CLJS.WI.tz.names())),$CLJS.NI],null)],null));
$CLJS.KF($CLJS.ZI,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hA,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ns,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vJ],null)],null)],null)],
null)]));$CLJS.BF($CLJS.ZI,$CLJS.fJ);$CLJS.IF($CLJS.mJ,$CLJS.H([$CLJS.st,$CLJS.dD]));$CLJS.X(bJ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ME],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,1,[$CLJS.zt,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Re(function(b){return $CLJS.AC(a,b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aD,$CLJS.AD],null))}],null)],null));
$CLJS.X(YI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.IE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zB,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bJ],null)],null)],null)],null));
$CLJS.UG.g($CLJS.eJ,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Kj,new $CLJS.h(null,1,[$CLJS.zt,"valid :absolute-datetime clause"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,$CLJS.eJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YI],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Kj,new $CLJS.h(null,1,[$CLJS.zt,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.LI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,$CLJS.ci],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mH],null)],null)],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Kj,new $CLJS.h(null,1,[$CLJS.zt,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,$CLJS.aw],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,$CLJS.ci],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iH],null)],null)],null)],null)],null)],null));
$CLJS.XE.m(null,$CLJS.eJ,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);a=$CLJS.E.g(b,$CLJS.aw)?$CLJS.E.g(a,$CLJS.ci)?$CLJS.AD:$CLJS.n($CLJS.AF($CLJS.mH,a))?$CLJS.aD:$CLJS.AD:null;if($CLJS.n(a))return a;a="string"===typeof b?$CLJS.n($CLJS.nh($CLJS.II,b))?$CLJS.aD:$CLJS.n($CLJS.nh($CLJS.JI,b))?$CLJS.aD:null:null;if($CLJS.n(a))return a;b=$CLJS.YE(b);b=$CLJS.vd(b)?$CLJS.eg.j($CLJS.bh,$CLJS.kf(function(c){return $CLJS.AC(c,$CLJS.bk)}),b):b;return $CLJS.vd(b)&&
$CLJS.E.g($CLJS.D(b),1)?$CLJS.A(b):b});$CLJS.X(rJ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,$CLJS.aw],null),$CLJS.yi],null));
$CLJS.KF($CLJS.kJ,$CLJS.H([$CLJS.st,$CLJS.AD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.si,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ns,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pE],null)],null)],null)],null)]));
$CLJS.IF($CLJS.Ny,$CLJS.H([$CLJS.st,$CLJS.$D,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oE],null)]));$CLJS.X($I,new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.Cs,$CLJS.kH,$CLJS.Ci,$CLJS.sk,$CLJS.gJ,$CLJS.BJ,$CLJS.CJ,$CLJS.Lj,$CLJS.Ii,$CLJS.wi,$CLJS.mj,$CLJS.hH],null));$CLJS.X(nJ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Cs,$CLJS.aJ,$CLJS.jJ,$CLJS.oJ],null));
$CLJS.KF($CLJS.wJ,$CLJS.H([$CLJS.st,$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.si,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$I],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ns,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nJ],null)],null)],
null)],null)]));