var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var KL,ML,OL,RL,WL;$CLJS.HL=function(a){return $CLJS.Wh($CLJS.q($CLJS.oC),a,$CLJS.xG)};$CLJS.IL=new $CLJS.M(null,"many-pks?","many-pks?",-459675021);$CLJS.JL=new $CLJS.M(null,"object-id","object-id",-754527291);KL=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);$CLJS.LL=new $CLJS.M(null,"operators","operators",-2064102509);
ML=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);$CLJS.NL=new $CLJS.M(null,"stage-number","stage-number",-1752729638);OL=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.PL=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.QL=new $CLJS.M(null,"dimensions","dimensions",-254818097);
RL=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.SL=new $CLJS.M("drill-thru","pk","drill-thru/pk",1721977789);$CLJS.TL=new $CLJS.M("drill-thru","fk-details","drill-thru/fk-details",-687193600);$CLJS.UL=new $CLJS.M(null,"max-value","max-value",687805168);$CLJS.VL=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);
WL=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.X(ML,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yt,$CLJS.IE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zG,new $CLJS.h(null,1,[$CLJS.Us,!0],null),$CLJS.qE],null)],null)],null));$CLJS.X(WL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yt,ML,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zB,$CLJS.ME],null)],null)],null));$CLJS.X(OL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,$CLJS.tG],null),WL,$CLJS.DE],null));
$CLJS.X(RL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,$CLJS.tG],null),ML,$CLJS.qL],null));
$CLJS.UG.g($CLJS.tG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,$CLJS.tG],null),ML,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,$CLJS.qL,$CLJS.DE],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Js,new $CLJS.h(null,2,[$CLJS.Ri,function(a){return $CLJS.wd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.mC(a)):null},$CLJS.zt,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.qB,RL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pB,OL],null)],null)],null));$CLJS.BF($CLJS.tG,$CLJS.xG);$CLJS.XE.m(null,$CLJS.tG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.HA($CLJS.Hi,$CLJS.zB)(b);return $CLJS.n(a)?a:$CLJS.jF});$CLJS.IF($CLJS.wB,$CLJS.H([$CLJS.DE]));$CLJS.XE.m(null,$CLJS.wB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.HA($CLJS.Hi,$CLJS.zB)(b);return $CLJS.n(a)?a:$CLJS.jF});
$CLJS.BF($CLJS.wB,$CLJS.xG);$CLJS.X(KL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yt,$CLJS.IE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Us,!0],null),$CLJS.DE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yE,new $CLJS.h(null,1,[$CLJS.Us,!0],null),$CLJS.DE],null)],null)],null));$CLJS.UG.g($CLJS.iG,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,$CLJS.iG],null),KL,$CLJS.ek],null));
$CLJS.XE.m(null,$CLJS.iG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.HA($CLJS.Hi,$CLJS.zB)(b);return $CLJS.n(a)?a:$CLJS.jF});$CLJS.BF($CLJS.iG,$CLJS.xG);$CLJS.IF($CLJS.SF,$CLJS.H([$CLJS.st,$CLJS.Qj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZK],null)],null)]));$CLJS.BF($CLJS.SF,$CLJS.xG);
$CLJS.IF($CLJS.oG,$CLJS.H([$CLJS.st,$CLJS.jF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XK],null)],null)]));$CLJS.BF($CLJS.oG,$CLJS.xG);
$CLJS.X($CLJS.xG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,$CLJS.ZE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,1,[$CLJS.Bt,function(){return["Valid reference, must be one of these clauses: ",$CLJS.Kt(", ",$CLJS.fH($CLJS.Jd,$CLJS.sl.g($CLJS.q($CLJS.oC),$CLJS.xG)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.HL(a)}],null)],null));