var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var FH,GH,IH,JH,Gfa,Hfa,Ifa,KH,HH;$CLJS.DH=function(a,b){return $CLJS.gc($CLJS.eb(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.hg.j(c,e,$CLJS.be.g($CLJS.J.j(c,e,$CLJS.Cf),d))},$CLJS.ec($CLJS.N),b))};$CLJS.EH=function(a,b){return $CLJS.eb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Sc(d):null},null,b)};
FH=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);b=$CLJS.AC(b,$CLJS.aD)?$CLJS.oH:$CLJS.AC(b,$CLJS.$D)?$CLJS.oE:$CLJS.AC(b,$CLJS.AD)?$CLJS.pE:null;return $CLJS.n(b)?$CLJS.AF(b,a):!0};
GH=function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);$CLJS.A(a);a=$CLJS.B(a);a=$CLJS.DH(function(d){return $CLJS.AC($CLJS.YE(d),$CLJS.lD)},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,!1);b=$CLJS.J.g(b,!0);if($CLJS.Uk.g($CLJS.D(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.D(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.YE($CLJS.A(a));return $CLJS.Re(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);$CLJS.I(d,2,null);
var e=$CLJS.I(d,3,null);return $CLJS.n(FH(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
IH=function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.h(null,1,[$CLJS.zt,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Kj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.IE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HH],null)],
null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,1,[$CLJS.Bt,function(b){b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.Hj);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(GH(b))].join("")}],null),$CLJS.Te(GH)],null)],null)};
JH=function(a){return new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Kj,new $CLJS.h(null,1,[$CLJS.zt,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.IE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.h(null,1,[$CLJS.go,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null)],null)],null)],null)};
Gfa=function(a){return $CLJS.Qd($CLJS.DF,$CLJS.hf.g(function(b){var c=$CLJS.YE(b),d=$CLJS.AC(c,$CLJS.jF);b=d?$CLJS.AF($CLJS.xG,b):d;return $CLJS.n(b)?$CLJS.Uj:c},a))};Hfa=function(a){a=$CLJS.EH(function(b){return!$CLJS.AC(b,$CLJS.lD)},$CLJS.hf.g($CLJS.YE,a));return $CLJS.AC(a,$CLJS.jF)?$CLJS.bk:a};
Ifa=function(a,b){return $CLJS.n($CLJS.Re(function(c){return $CLJS.AC($CLJS.YE(c),$CLJS.lD)},b))?Hfa(b):$CLJS.E.g(a,$CLJS.st)&&$CLJS.E.g($CLJS.D(b),2)&&($CLJS.Qe(function(c){return $CLJS.AC($CLJS.YE(c),$CLJS.aD)},b)||$CLJS.Qe(function(c){return $CLJS.AC($CLJS.YE(c),$CLJS.AD)},b))?$CLJS.lD:Gfa(b)};KH=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);
$CLJS.LH=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);HH=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.X(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Li,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,HH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,1,[$CLJS.zt,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);var c=$CLJS.YE(a);return $CLJS.Qe(function(d){return FH(d,c)},b)}],null)],null));
$CLJS.X(KH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.h(null,1,[$CLJS.go,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null)],null)],null));
var Jfa=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Kj,new $CLJS.h(null,1,[$CLJS.zt,":- clause taking the difference of two temporal expressions"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,$CLJS.st],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.IE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fF],null)],null)],
null);$CLJS.UG.g($CLJS.ls,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,IH($CLJS.ls),JH($CLJS.ls)],null));$CLJS.UG.g($CLJS.st,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fs,IH($CLJS.st),Jfa,JH($CLJS.st)],null));$CLJS.KF($CLJS.ms,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.et,KH],null)]));$CLJS.KF($CLJS.PG,$CLJS.H([$CLJS.st,$CLJS.XD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.et,KH],null)]));
for(var MH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ls,$CLJS.st,$CLJS.ms],null)),NH=null,OH=0,PH=0;;)if(PH<OH){var Kfa=NH.X(null,PH);$CLJS.BF(Kfa,$CLJS.LH);PH+=1}else{var QH=$CLJS.y(MH);if(QH){var RH=QH;if($CLJS.Ad(RH)){var SH=$CLJS.lc(RH),Lfa=$CLJS.mc(RH),Mfa=SH,Nfa=$CLJS.D(SH);MH=Lfa;NH=Mfa;OH=Nfa}else{var Ofa=$CLJS.A(RH);$CLJS.BF(Ofa,$CLJS.LH);MH=$CLJS.B(RH);NH=null;OH=0}PH=0}else break}
$CLJS.XE.m(null,$CLJS.LH,function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);$CLJS.A(b);b=$CLJS.B(b);return Ifa(a,b)});$CLJS.IF($CLJS.UF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null)],null)]));$CLJS.BF($CLJS.UF,$CLJS.nF);
for(var TH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RG,$CLJS.LF,$CLJS.gG],null)),UH=null,VH=0,WH=0;;)if(WH<VH){var Pfa=UH.X(null,WH);$CLJS.IF(Pfa,$CLJS.H([$CLJS.st,$CLJS.XD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null)],null)]));WH+=1}else{var XH=$CLJS.y(TH);if(XH){var YH=XH;if($CLJS.Ad(YH)){var ZH=$CLJS.lc(YH),Qfa=$CLJS.mc(YH),Rfa=ZH,Sfa=$CLJS.D(ZH);TH=Qfa;UH=Rfa;VH=Sfa}else{var Tfa=$CLJS.A(YH);$CLJS.IF(Tfa,$CLJS.H([$CLJS.st,$CLJS.XD,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null)],null)]));TH=$CLJS.B(YH);UH=null;VH=0}WH=0}else break}
for(var $H=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PF,$CLJS.rG,$CLJS.yG],null)),aI=null,bI=0,cI=0;;)if(cI<bI){var Ufa=aI.X(null,cI);$CLJS.IF(Ufa,$CLJS.H([$CLJS.st,$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null)],null)]));cI+=1}else{var dI=$CLJS.y($H);if(dI){var eI=dI;if($CLJS.Ad(eI)){var fI=$CLJS.lc(eI),Vfa=$CLJS.mc(eI),Wfa=fI,Xfa=$CLJS.D(fI);$H=Vfa;aI=Wfa;bI=Xfa}else{var Yfa=$CLJS.A(eI);$CLJS.IF(Yfa,$CLJS.H([$CLJS.st,$CLJS.Aj,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null)],null)]));$H=$CLJS.B(eI);aI=null;bI=0}cI=0}else break}$CLJS.IF($CLJS.RF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null)],null)]));
$CLJS.XE.m(null,$CLJS.RF,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.AC($CLJS.YE(b),$CLJS.Aj)&&$CLJS.AC($CLJS.YE(a),$CLJS.Aj)?$CLJS.Aj:$CLJS.XD});