var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var l4,n4,o4,p4,Lpa;l4=function(a,b,c){var d=$CLJS.NW(a,b);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.iG);d=$CLJS.EH($CLJS.Wk.j($CLJS.ch([c]),$CLJS.KE,$CLJS.hd),d);if(!$CLJS.n(d))throw $CLJS.hi($CLJS.tE("No aggregation with uuid {0}",$CLJS.H([c])),new $CLJS.h(null,3,[$CLJS.ik,c,$CLJS.QF,a,$CLJS.NL,b],null));return d};$CLJS.m4=function(a){return $CLJS.f2($CLJS.GG,new $CLJS.P(null,1,5,$CLJS.Q,[a],null))};
n4=new $CLJS.M("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);o4=new $CLJS.M("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);p4=new $CLJS.M("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);Lpa=new $CLJS.M(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.J0.m(null,$CLJS.iG,function(a,b){var c=$CLJS.Fe($CLJS.iG.h($CLJS.NW(a,b)));return $CLJS.n(c)?$CLJS.h2($CLJS.VE("and"),function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u);v=$CLJS.g0.v(a,b,v,$CLJS.h0);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),null)}t=$CLJS.A(k);return $CLJS.ee($CLJS.g0.v(a,
b,t,$CLJS.h0),f($CLJS.Lc(k)))}return null}},null,null)}(c)}()):null});$CLJS.M0.m(null,$CLJS.iG,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.zB);e=$CLJS.J.g(e,$CLJS.Hi);c=$CLJS.I(c,2,null);c=l4(a,b,c);return $CLJS.nl.l($CLJS.H([$CLJS.f0.j(a,b,c),new $CLJS.h(null,2,[$CLJS.YK,$CLJS.sL,$CLJS.j1,$CLJS.KE.h($CLJS.hd(c))],null),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.zB,d],null):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.Hi,e],null):null]))});
$CLJS.G0.m(null,$CLJS.iG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.g0.v(a,b,l4(a,b,c),d)});$CLJS.BF(o4,p4);
for(var q4=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zx,$CLJS.uG],null)),r4=null,s4=0,t4=0;;)if(t4<s4){var Mpa=r4.X(null,t4);$CLJS.BF(Mpa,o4);t4+=1}else{var u4=$CLJS.y(q4);if(u4){var v4=u4;if($CLJS.Ad(v4)){var w4=$CLJS.lc(v4),Npa=$CLJS.mc(v4),Opa=w4,Ppa=$CLJS.D(w4);q4=Npa;r4=Opa;s4=Ppa}else{var Qpa=$CLJS.A(v4);$CLJS.BF(Qpa,o4);q4=$CLJS.B(v4);r4=null;s4=0}t4=0}else break}
$CLJS.G0.m(null,o4,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);if($CLJS.n(c))switch(a=$CLJS.g0.v(a,b,c,d),e=e instanceof $CLJS.M?e.T:null,e){case "count":return $CLJS.tE("Count of {0}",$CLJS.H([a]));case "cum-count":return $CLJS.tE("Cumulative count of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}else switch(e=e instanceof $CLJS.M?e.T:null,e){case "count":return $CLJS.VE("Count");case "cum-count":return $CLJS.VE("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.H0.m(null,o4,function(a,b,c){a=$CLJS.I(c,0,null);a=a instanceof $CLJS.M?a.T:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});$CLJS.M0.m(null,o4,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.xI($CLJS.M0,p4);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.ak,$CLJS.bD)});$CLJS.G0.m(null,$CLJS.cG,function(){return $CLJS.VE("Case")});
$CLJS.H0.m(null,$CLJS.cG,function(){return"case"});$CLJS.BF(n4,p4);for(var x4=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.nG,$CLJS.QG,$CLJS.ZF,$CLJS.pk,$CLJS.HG,$CLJS.go,$CLJS.JG,$CLJS.GG,$CLJS.$F],null)),y4=null,z4=0,A4=0;;)if(A4<z4){var Rpa=y4.X(null,A4);$CLJS.BF(Rpa,n4);A4+=1}else{var B4=$CLJS.y(x4);if(B4){var C4=B4;if($CLJS.Ad(C4)){var D4=$CLJS.lc(C4),Spa=$CLJS.mc(C4),Tpa=D4,Upa=$CLJS.D(D4);x4=Spa;y4=Tpa;z4=Upa}else{var Vpa=$CLJS.A(C4);$CLJS.BF(Vpa,n4);x4=$CLJS.B(C4);y4=null;z4=0}A4=0}else break}
$CLJS.H0.m(null,n4,function(a,b,c){a=$CLJS.I(c,0,null);$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=a instanceof $CLJS.M?a.T:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.G0.m(null,n4,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);a=$CLJS.g0.v(a,b,c,d);e=e instanceof $CLJS.M?e.T:null;switch(e){case "avg":return $CLJS.tE("Average of {0}",$CLJS.H([a]));case "cum-sum":return $CLJS.tE("Cumulative sum of {0}",$CLJS.H([a]));case "distinct":return $CLJS.tE("Distinct values of {0}",$CLJS.H([a]));case "max":return $CLJS.tE("Max of {0}",$CLJS.H([a]));case "median":return $CLJS.tE("Median of {0}",$CLJS.H([a]));case "min":return $CLJS.tE("Min of {0}",
$CLJS.H([a]));case "stddev":return $CLJS.tE("Standard deviation of {0}",$CLJS.H([a]));case "sum":return $CLJS.tE("Sum of {0}",$CLJS.H([a]));case "var":return $CLJS.tE("Variance of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.G0.m(null,$CLJS.eG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);c=$CLJS.I(c,3,null);return $CLJS.tE("{0}th percentile of {1}",$CLJS.H([c,$CLJS.g0.v(a,b,e,d)]))});$CLJS.H0.m(null,$CLJS.eG,function(){return"percentile"});
$CLJS.BF($CLJS.eG,p4);$CLJS.G0.m(null,$CLJS.OG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.tE("Sum of {0} matching condition",$CLJS.H([$CLJS.g0.v(a,b,e,d)]))});$CLJS.H0.m(null,$CLJS.OG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return["sum_where_",$CLJS.p.h($CLJS.I0.j(a,b,d))].join("")});$CLJS.BF($CLJS.OG,p4);$CLJS.G0.m(null,$CLJS.AG,function(){return $CLJS.VE("Share of rows matching condition")});
$CLJS.H0.m(null,$CLJS.AG,function(){return"share"});$CLJS.BF($CLJS.AG,p4);$CLJS.G0.m(null,$CLJS.CG,function(){return $CLJS.VE("Count of rows matching condition")});$CLJS.H0.m(null,$CLJS.CG,function(){return"count-where"});$CLJS.BF($CLJS.CG,p4);
$CLJS.M0.m(null,p4,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.nl.l;d=$CLJS.n(d)?$CLJS.lm($CLJS.f0.j(a,b,d),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.lL],null)):null;var f=$CLJS.xI($CLJS.M0,$CLJS.ci);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.nl,$CLJS.H([d,a]))});
$CLJS.Wpa=function(){function a(d){return $CLJS.f2($CLJS.Zx,new $CLJS.P(null,1,5,$CLJS.Q,[d],null))}function b(){return $CLJS.f2($CLJS.Zx,$CLJS.Cf)}var c=null;c=function(d){switch(arguments.length){case 0:return b.call(this);case 1:return a.call(this,d)}throw Error("Invalid arity: "+arguments.length);};c.o=b;c.h=a;return c}();$CLJS.wW.m(null,$CLJS.iG,function(a){return a});
$CLJS.E4=function(){function a(d,e,f){for(;;)if($CLJS.E.g($CLJS.mC(f),$CLJS.bL))f=$CLJS.yW(f);else return $CLJS.i2(d,e,$CLJS.iG,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.F4=function(){function a(d,e){return $CLJS.Fe($CLJS.iG.h($CLJS.NW(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.G4=function(){function a(d,e){var f=$CLJS.Fe($CLJS.iG.h($CLJS.NW(d,e)));return null==f?null:$CLJS.eg.j($CLJS.Cf,$CLJS.hf.h(function(k){var l=$CLJS.f0.j(d,e,k),m=$CLJS.R.l,t=$CLJS.zB.h(l);return m.call($CLJS.R,$CLJS.e2(l,$CLJS.Hi,$CLJS.n(t)?t:$CLJS.Ej),$CLJS.YK,$CLJS.sL,$CLJS.H([$CLJS.j1,$CLJS.KE.h($CLJS.hd(k))]))}),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.g=a;return c}();$CLJS.G0.m(null,$CLJS.cH,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.ZG);return $CLJS.yE.h(a.o?a.o():a.call(null))});$CLJS.N0.m(null,$CLJS.cH,function(a,b,c){var d=$CLJS.O(c);a=$CLJS.J.g(d,$CLJS.mG);b=$CLJS.J.g(d,$CLJS.ZG);c=$CLJS.J.g(d,$CLJS.XG);d=$CLJS.J.g(d,$CLJS.SZ);a=$CLJS.R.l(b.o?b.o():b.call(null),$CLJS.E0,$CLJS.eB(a),$CLJS.H([$CLJS.C0,c]));return null!=d?$CLJS.R.j(a,$CLJS.o0,d):a});
$CLJS.Xpa=function(){function a(d,e){var f=function(){var m=$CLJS.nA.h($CLJS.A1($CLJS.$_(d)));return $CLJS.n(m)?m:$CLJS.bh}(),k=$CLJS.NW(d,e),l=$CLJS.R0.j(d,e,k);return $CLJS.Fe($CLJS.eg.j($CLJS.Cf,$CLJS.Wk.j($CLJS.kf(function(m){m=$CLJS.aH.h(m);var t=null==m;return t?t:f.h?f.h(m):f.call(null,m)}),$CLJS.dm(function(m){m=$CLJS.O(m);var t=$CLJS.J.g(m,$CLJS.XG),u=$CLJS.J.g(m,$CLJS.VG);if($CLJS.Va(t))return m;if($CLJS.E.g(u,$CLJS.li))return $CLJS.R.j(m,$CLJS.KW,l);t=$CLJS.Fe($CLJS.e1(function(v){return $CLJS.C_(u,
v)},l));return $CLJS.n(t)?$CLJS.R.j(m,$CLJS.KW,t):null}),$CLJS.hf.h(function(m){return $CLJS.R.j(m,$CLJS.Ij,$CLJS.cH)})),$CLJS.dH))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Ypa=function(){function a(d,e){return $CLJS.xW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mG.h(d),$CLJS.N,$CLJS.zW.h(e)],null))}function b(d){if($CLJS.Va($CLJS.XG.h(d)))return $CLJS.xW(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mG.h(d),$CLJS.N],null));var e=$CLJS.mG.h(d);e=$CLJS.fa.g?$CLJS.fa.g("aggregation operator %s requires an argument",e):$CLJS.fa.call(null,"aggregation operator %s requires an argument",e);throw $CLJS.hi(e,new $CLJS.h(null,1,[Lpa,d],null));}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,
d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();