var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var WE,aF,Vea,Wea,Xea,Yea,Zea,$ea;
$CLJS.UE=function(){function a(){return Math.floor(16*Math.random()).toString(16)}var b=(8|3&Math.floor(16*Math.random())).toString(16);return new $CLJS.oA([$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-4",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(b),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),
$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a())].join("").toLowerCase())};$CLJS.VE=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return $CLJS.tE(arguments[0],1<b.length?new $CLJS.w(b.slice(1),0,null):null)};WE=function(a){return $CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M};
$CLJS.YE=function(a){var b=function(){var c=WE(a);return c?(c=$CLJS.xd($CLJS.hd(a)))?(c=$CLJS.Hi.h($CLJS.hd(a)),$CLJS.n(c)?c:$CLJS.zB.h($CLJS.hd(a))):c:c}();return $CLJS.n(b)?b:$CLJS.XE.h(a)};
aF=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,2,[$CLJS.zt,"valid MBQL clause",$CLJS.Bt,function(c){c=$CLJS.O(c);c=$CLJS.J.g(c,$CLJS.Hj);return["invalid MBQL clause: ",$CLJS.Mh.l($CLJS.H([c]))].join("")}],null),$CLJS.Te(WE)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,1,[$CLJS.zt,b],null),function(c){c=$CLJS.YE(c);
return $E(c,a)}],null)],null)};$CLJS.bF=new $CLJS.M(null,"right-join","right-join",-56349359);Vea=new $CLJS.M("metabase.lib.schema.expression","datetime","metabase.lib.schema.expression/datetime",-51960022);Wea=new $CLJS.M("metabase.lib.schema.expression","base-type","metabase.lib.schema.expression/base-type",578343967);Xea=new $CLJS.M("metabase.lib.schema.expression","non-integer-real","metabase.lib.schema.expression/non-integer-real",-1855491016);$CLJS.cF=new $CLJS.M(null,"fields","fields",-1932066230);
$CLJS.dF=new $CLJS.M(null,"expr","expr",745722291);$CLJS.eF=new $CLJS.M("metabase.lib.schema.expression","expressions","metabase.lib.schema.expression/expressions",790922225);$CLJS.fF=new $CLJS.M("metabase.lib.schema.expression","temporal","metabase.lib.schema.expression/temporal",320116754);$CLJS.gF=new $CLJS.M(null,"alias","alias",-2039751630);$CLJS.hF=new $CLJS.M("metabase.lib.schema.expression","boolean","metabase.lib.schema.expression/boolean",-1396506592);
$CLJS.iF=new $CLJS.M("metabase.lib.schema.expression","expression","metabase.lib.schema.expression/expression",-1389098704);$CLJS.jF=new $CLJS.M("metabase.lib.schema.expression","type.unknown","metabase.lib.schema.expression/type.unknown",-98159107);$CLJS.kF=new $CLJS.M("mbql.clause","field","mbql.clause/field",1497292735);Yea=new $CLJS.M("metabase.lib.schema.expression","date","metabase.lib.schema.expression/date",1943847782);$CLJS.lF=new $CLJS.M(null,"left-join","left-join",-672831855);
$CLJS.mF=new $CLJS.M("metabase.lib.schema.expression","equality-comparable","metabase.lib.schema.expression/equality-comparable",-658449046);$CLJS.nF=new $CLJS.M("lib.type-of","type-is-type-of-first-arg","lib.type-of/type-is-type-of-first-arg",-317600808);$CLJS.oF=new $CLJS.M("metabase.lib.schema.expression","orderable","metabase.lib.schema.expression/orderable",-1555566130);Zea=new $CLJS.r("metabase.lib.schema.expression","type-of","metabase.lib.schema.expression/type-of",-1625245814,null);
$ea=new $CLJS.M("metabase.lib.schema.expression","time","metabase.lib.schema.expression/time",865466446);$CLJS.pF=new $CLJS.M(null,"inner-join","inner-join",659431740);$CLJS.qF=new $CLJS.M("metabase.lib.schema.expression","emptyable","metabase.lib.schema.expression/emptyable",941775581);$CLJS.ZE=new $CLJS.M("metabase.lib.schema.mbql-clause","clause","metabase.lib.schema.mbql-clause/clause",955279388);
$CLJS.rF=new $CLJS.M("metabase.lib.schema.expression","string","metabase.lib.schema.expression/string",1750008170);$CLJS.sF=new $CLJS.M(null,"full-join","full-join",1305476385);$CLJS.tF=new $CLJS.M("metabase.lib.schema.expression","number","metabase.lib.schema.expression/number",779948930);$CLJS.uF=new $CLJS.M("metabase.lib.schema.expression","integer","metabase.lib.schema.expression/integer",2060430870);$CLJS.XE=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.lj,$CLJS.oC],null),$CLJS.lj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.schema.expression","type-of-method"),function(f){var k=$CLJS.mC(f);return $CLJS.E.g(k,$CLJS.yB)?$CLJS.ab(f):k},e,a,b,c,d)}();
$CLJS.X(Wea,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,$CLJS.jF],null),$CLJS.ME],null));$CLJS.XE.m(null,$CLJS.ci,function(a){throw $CLJS.hi($CLJS.tE("{0}: Don''t know how to determine the type of {1}",$CLJS.H([Zea,$CLJS.Mh.l($CLJS.H([a]))])),new $CLJS.h(null,1,[$CLJS.dF,a],null));});$CLJS.XE.m(null,$CLJS.nF,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.YE(a)});
var $E=function $E(a,b){return $CLJS.vd(a)?$CLJS.Re(function(d){return $E.g?$E.g(d,b):$E.call(null,d,b)},a):$CLJS.vd(b)?$CLJS.Re(function(d){return $E.g?$E.g(a,d):$E.call(null,a,d)},b):$CLJS.E.g(a,$CLJS.jF)?!0:$CLJS.AC(a,b)};$CLJS.X($CLJS.hF,aF($CLJS.Qj,"expression returning a boolean"));$CLJS.X($CLJS.rF,aF($CLJS.jk,"expression returning a string"));$CLJS.X($CLJS.uF,aF($CLJS.Aj,"expression returning an integer"));$CLJS.X(Xea,aF($CLJS.XD,"expression returning a non-integer real number"));
$CLJS.X($CLJS.tF,aF($CLJS.Uj,"expression returning a number"));$CLJS.X(Yea,aF($CLJS.aD,"expression returning a date"));$CLJS.X($ea,aF($CLJS.$D,"expression returning a time"));$CLJS.X(Vea,aF($CLJS.AD,"expression returning a date time"));$CLJS.X($CLJS.fF,aF($CLJS.bk,"expression returning a date, time, or date time"));$CLJS.vF=new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.Uj,null,$CLJS.jk,null,$CLJS.bk,null,$CLJS.Qj,null],null),null);$CLJS.X($CLJS.oF,aF($CLJS.vF,"an expression that can be compared with :\x3e or :\x3c"));
var afa=new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.KC,null,$CLJS.Uj,null,$CLJS.jk,null,$CLJS.qD,null,$CLJS.bk,null,$CLJS.Ej,null,$CLJS.Qj,null,$CLJS.VD,null],null),null);$CLJS.X($CLJS.qF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null),aF($CLJS.qD,"expression returning a BSONID")],null));$CLJS.X($CLJS.mF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,aF(afa,"an expression that can appear in :\x3d or :!\x3d")],null));
$CLJS.X($CLJS.iF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,aF($CLJS.Ej,"any type of expression")],null));
$CLJS.X($CLJS.eF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.h(null,1,[$CLJS.go,1],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Kj,$CLJS.li,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CE,$CLJS.ek],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ms,$CLJS.li],null)],null)],null)],null));