var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.lib.cache.js");require("./metabase.lib.convert.js");require("./metabase.lib.core.js");require("./metabase.lib.equality.js");require("./metabase.lib.join.js");require("./metabase.lib.js.metadata.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.order_by.js");require("./metabase.lib.stage.js");require("./metabase.lib.util.js");require("./metabase.mbql.js.js");require("./metabase.mbql.normalize.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.memoize.js");
'use strict';var rwa=function(a,b){const c={};for(const d in a)b.call(void 0,a[d],d,a)&&(c[d]=a[d]);return c},swa=function(a,b){const c={};for(const d in a)c[d]=b.call(void 0,a[d],d,a);return c},twa=function(a,b,c,d){var e=$CLJS.eg.j($CLJS.N,$CLJS.nm(function(f,k){return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),d);return function l(k){return new $CLJS.ne(null,function(){for(;;){var m=$CLJS.y(k);if(m){if($CLJS.Ad(m)){var t=$CLJS.lc(m),u=$CLJS.D(t),v=$CLJS.qe(u);a:for(var x=0;;)if(x<u){var z=$CLJS.kd(t,
x);z=$CLJS.r2.v(a,b,z,d);z=$CLJS.J.j(e,z,-1);v.add(z);x+=1}else{t=!0;break a}return t?$CLJS.te($CLJS.ve(v),l($CLJS.mc(m))):$CLJS.te($CLJS.ve(v),null)}v=$CLJS.A(m);v=$CLJS.r2.v(a,b,v,d);return $CLJS.ee($CLJS.J.j(e,v,-1),l($CLJS.Lc(m)))}return null}},null,null)}(c)},B9=function(a,b){return new $CLJS.Jh(function(){try{return $CLJS.x9.g(a,b)}catch(e){if(e instanceof Error){var c=e,d=$CLJS.RA($CLJS.Ky);$CLJS.n($CLJS.QA("metabase.lib.js.metadata",d))&&(c instanceof Error?$CLJS.PA("metabase.lib.js.metadata",
d,$CLJS.CW("Error parsing %s objects: %s",$CLJS.H([a,$CLJS.BV(c)])),c):$CLJS.PA("metabase.lib.js.metadata",d,$CLJS.CW(c,$CLJS.H(["Error parsing %s objects: %s",a,$CLJS.BV(c)])),null));return null}throw e;}})},uwa=function(a,b){return function e(d){return new $CLJS.ne(null,function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);return function(){for(var x=0;;)if(x<l){var z=$CLJS.kd(k,x);$CLJS.I(z,0,null);var C=$CLJS.I(z,1,null),G=function(){var K=C;return null==
K?null:$CLJS.q(K)}();$CLJS.n(function(){var K=G;return $CLJS.n(K)?$CLJS.E.g($CLJS.kL.h(G),b):K}())&&m.add(G);x+=1}else return!0}()?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}var t=$CLJS.A(f);$CLJS.I(t,0,null);var u=$CLJS.I(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=v;return $CLJS.n(x)?$CLJS.E.g($CLJS.kL.h(v),b):x}()))return $CLJS.ee(v,e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.cF.h(a);
return null==d?null:$CLJS.q(d)}())},vwa=function(a,b){return function e(d){return new $CLJS.ne(null,function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);return function(){for(var x=0;;)if(x<l){var z=$CLJS.kd(k,x);$CLJS.I(z,0,null);var C=$CLJS.I(z,1,null),G=function(){var K=C;return null==K?null:$CLJS.q(K)}();$CLJS.n(function(){var K=G;return $CLJS.n(K)?$CLJS.E.g($CLJS.kL.h(G),b):K}())&&m.add(G);x+=1}else return!0}()?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):
$CLJS.te($CLJS.ve(m),null)}var t=$CLJS.A(f);$CLJS.I(t,0,null);var u=$CLJS.I(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=v;return $CLJS.n(x)?$CLJS.E.g($CLJS.kL.h(v),b):x}()))return $CLJS.ee(v,e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.s9.h(a);return null==d?null:$CLJS.q(d)}())},wwa=function(a,b){return function e(d){return new $CLJS.ne(null,function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.Ad(f)){var k=
$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);return function(){for(var x=0;;)if(x<l){var z=$CLJS.kd(k,x);$CLJS.I(z,0,null);var C=$CLJS.I(z,1,null),G=function(){var K=C;return null==K?null:$CLJS.q(K)}();$CLJS.n(function(){var K=G;return $CLJS.n(K)?$CLJS.E.g($CLJS.kL.h(G),b):K}())&&m.add(G);x+=1}else return!0}()?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}var t=$CLJS.A(f);$CLJS.I(t,0,null);var u=$CLJS.I(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=
v;return $CLJS.n(x)?$CLJS.E.g($CLJS.kL.h(v),b):x}()))return $CLJS.ee(v,e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.t9.h(a);return null==d?null:$CLJS.q(d)}())},C9=function(a,b,c,d){this.fg=a;this.Ii=b;this.tc=c;this.ji=d;this.C=393216;this.I=0},xwa=function(a){return $CLJS.Xa(a)?rwa(a,function(b){return void 0!==b}):a},D9=function(a){return $CLJS.Jt($CLJS.Vz(swa(a,function(b){return xwa(b)})),function(b){return $CLJS.Zk.j($CLJS.JA(b,$CLJS.zh),$CLJS.qj,
$CLJS.zh)})},E9=function(a,b){if($CLJS.DZ(b))a=b;else{var c=new $CLJS.h(null,6,[$CLJS.w9,B9($CLJS.cQ,b),$CLJS.u9,B9($CLJS.WW,b),$CLJS.cF,B9($CLJS.tG,b),$CLJS.r9,B9($CLJS.EN,b),$CLJS.s9,B9($CLJS.oG,b),$CLJS.t9,B9($CLJS.SF,b)],null),d=$CLJS.RA($CLJS.Jy);$CLJS.n($CLJS.QA("metabase.lib.js.metadata",d))&&$CLJS.PA("metabase.lib.js.metadata",d,$CLJS.Iw.l($CLJS.H(["Created metadata provider for metadata"])),null);a=new C9(a,b,c,$CLJS.N)}return a},H9=function(a){return $CLJS.xd(a)?F9.h?F9.h(a):F9.call(null,
a):$CLJS.wd(a)?G9.h?G9.h(a):G9.call(null,a):a instanceof $CLJS.M?$CLJS.eB(a):a},I9=function(a,b){return $CLJS.bX($CLJS.bX((0,$CLJS.JY)($CLJS.A9($CLJS.Vz(a))),$CLJS.sQ,function(c){return $CLJS.nl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.PQ,$CLJS.N],null),c]))}),$CLJS.QF,function(c){var d=function(){var e=$CLJS.cF.h(c);return $CLJS.n(e)?e:function l(k){return new $CLJS.ne(null,function(){for(;;){var m=$CLJS.y(k);if(m){if($CLJS.Ad(m)){var t=$CLJS.lc(m),u=$CLJS.D(t),v=$CLJS.qe(u);a:for(var x=0;;)if(x<u){var z=
$CLJS.kd(t,x);v.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tG,z,null],null));x+=1}else{t=!0;break a}return t?$CLJS.te($CLJS.ve(v),l($CLJS.mc(m))):$CLJS.te($CLJS.ve(v),null)}v=$CLJS.A(m);return $CLJS.ee(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tG,v,null],null),l($CLJS.Lc(m)))}return null}},null,null)}(b)}();return $CLJS.R.j(c,$CLJS.cF,$CLJS.c8(d))})},ywa=function(a,b){var c=$CLJS.NW(a,b);return $CLJS.we($CLJS.hf.g(function(d){return $CLJS.R.j(d,$CLJS.SZ,!0)},$CLJS.P0.j(a,b,c)))},J9=function(a){return $CLJS.fZ.h($CLJS.Zk.j($CLJS.Uz(a,
$CLJS.H([$CLJS.Bi,!0])),0,$CLJS.zh))},zwa=function(a){var b=$CLJS.Xa(a)?a:null;return $CLJS.n(b)?(a=$CLJS.z9.h?$CLJS.z9.h(b):$CLJS.z9.call(null,b),$CLJS.E.g($CLJS.YK.h(a),$CLJS.sL)?(b=J9(b.field_ref),$CLJS.R.j(a,$CLJS.j1,$CLJS.id(b))):a):a},K9=function(a,b){var c=null==a?null:$CLJS.A(a);c=null==c?null:$CLJS.E.g(c,$CLJS.iG);return $CLJS.n(c)?$CLJS.R.j(b,$CLJS.j1,$CLJS.id(a)):b},L9=function(a){return function(b){var c=function(){var e=a.h?a.h(b):a.call(null,b);return $CLJS.z9.h?$CLJS.z9.h(e):$CLJS.z9.call(null,
e)}(),d=function(){var e=$CLJS.R1.h(c);return $CLJS.n(e)?J9(e):null}();return new $CLJS.h(null,3,[$CLJS.Wi,K9(d,c),$CLJS.g6,d,$CLJS.Hj,b.value],null)}};$CLJS.g=C9.prototype;$CLJS.g.P=function(a,b){return new C9(this.fg,this.Ii,this.tc,b)};$CLJS.g.O=function(){return this.ji};$CLJS.g.ki=$CLJS.Bc;$CLJS.g.vg=function(a,b){a=this.tc;a=null==a?null:$CLJS.s9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.wg=function(a,b){return vwa(this.tc,b)};
$CLJS.g.zg=function(a,b){a=this.tc;a=null==a?null:$CLJS.u9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.ug=function(a,b){return uwa(this.tc,b)};$CLJS.g.tg=function(a,b){a=this.tc;a=null==a?null:$CLJS.cF.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.yg=function(a,b){return wwa(this.tc,b)};
$CLJS.g.rg=function(a,b){a=this.tc;a=null==a?null:$CLJS.r9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.xg=function(a,b){a=this.tc;a=null==a?null:$CLJS.t9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.sg=function(){var a=this.tc,b=this.fg;a=null==a?null:$CLJS.w9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};var M9=function M9(a){switch(arguments.length){case 1:return M9.h(arguments[0]);case 2:return M9.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.za("metabase.lib.js.extract_template_tags",M9);M9.h=function(a){return M9.g(a,$CLJS.N)};M9.g=function(a,b){return $CLJS.rl($CLJS.pva.l($CLJS.H([a,D9(b)])))};M9.A=2;
var N9=function N9(a){return $CLJS.me(a)?[$CLJS.ie(a),"/",$CLJS.gh(a)].join(""):$CLJS.xd(a)?$CLJS.Jt(a,N9):$CLJS.wd(a)?$CLJS.hf.g(N9,a):a},F9=$CLJS.dB(function(a){return $CLJS.eb(function(b,c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);d=$CLJS.eB(d);const e=d.length-1;d=0<=e&&d.indexOf("?",e)==e?["is-",$CLJS.LA(d,/\?$/,"")].join(""):d;d=$CLJS.OB.h?$CLJS.OB.h(d):$CLJS.OB.call(null,d);c=H9.h?H9.h(c):H9.call(null,c);b[d]=c;return b},{},a)}),G9=$CLJS.dB(function(a){return $CLJS.we($CLJS.hf.g(H9,a))}),
O9=function O9(a){switch(arguments.length){case 1:return O9.h(arguments[0]);case 2:return O9.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.za("metabase.lib.js.order_by_clause",O9);O9.h=function(a){return O9.g(a,$CLJS.qG)};O9.g=function(a,b){return $CLJS.sva.l($CLJS.H([$CLJS.e9.l($CLJS.H([$CLJS.Uz(a,$CLJS.H([$CLJS.Bi,!0]))])),$CLJS.zh.h(b)]))};O9.A=2;
var P9=function P9(a){switch(arguments.length){case 2:return P9.g(arguments[0],arguments[1]);case 3:return P9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.za("metabase.lib.js.available_binning_strategies",P9);P9.g=function(a,b){return $CLJS.we($CLJS.d9.l($CLJS.H([a,b])))};P9.j=function(a,b,c){return $CLJS.we($CLJS.d9.l($CLJS.H([a,b,c])))};P9.A=3;
var Q9=function Q9(a){switch(arguments.length){case 2:return Q9.g(arguments[0],arguments[1]);case 3:return Q9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.za("metabase.lib.js.available_temporal_buckets",Q9);Q9.g=function(a,b){return $CLJS.we($CLJS.g9.l($CLJS.H([a,b])))};Q9.j=function(a,b,c){return $CLJS.we($CLJS.g9.l($CLJS.H([a,b,c])))};Q9.A=3;
var R9=function R9(a){switch(arguments.length){case 2:return R9.g(arguments[0],arguments[1]);case 3:return R9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.za("metabase.lib.js.query_EQ_",R9);R9.g=function(a,b){return R9.j(a,b,null)};R9.j=function(a,b,c){a=I9(a,c);b=I9(b,c);return $CLJS.E.g(a,b)};R9.A=3;
var S9=function S9(a){switch(arguments.length){case 1:return S9.h(arguments[0]);case 2:return S9.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.za("metabase.lib.js.aggregation_clause",S9);S9.h=function(a){return $CLJS.b9.l($CLJS.H([a]))};S9.g=function(a,b){return $CLJS.b9.l($CLJS.H([a,b]))};S9.A=2;
var T9=function T9(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return T9.l(arguments[0],arguments[1],2<c.length?new $CLJS.w(c.slice(2),0,null):null)};$CLJS.za("metabase.lib.js.filter_clause",T9);T9.l=function(a,b,c){return $CLJS.Se.v($CLJS.Hua,a,b,c)};T9.A=2;T9.B=function(a){var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);c=$CLJS.B(c);return this.l(b,a,c)};
var U9=function U9(a){switch(arguments.length){case 3:return U9.j(arguments[0],arguments[1],arguments[2]);case 4:return U9.v(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.za("metabase.lib.js.with_different_database",U9);U9.j=function(a,b,c){return U9.v(a,b,c,null)};U9.v=function(a,b,c,d){return $CLJS.nva.l($CLJS.H([a,E9(b,c),$CLJS.Uz(d,$CLJS.H([$CLJS.Bi,!0]))]))};U9.A=4;
var Awa=L9(function(a){return a.col}),Bwa=L9(function(a){return a.column}),V9=function V9(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return V9.l(arguments[0],arguments[1],arguments[2],3<c.length?new $CLJS.w(c.slice(3),0,null):null)};$CLJS.za("metabase.lib.js.drill_thru",V9);V9.l=function(a,b,c,d){return $CLJS.Se.N($CLJS.iua,a,b,c,d)};V9.A=3;
V9.B=function(a){var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);c=$CLJS.A(d);d=$CLJS.B(d);return this.l(b,a,c,d)};
module.exports={returned_columns:function(a,b){return $CLJS.e0($CLJS.zh.g("returned-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){return ywa(a,b)})},legacy_expression_for_expression_clause:function(a,b,c){b=$CLJS.c9.l($CLJS.H([a,b]));a=$CLJS.eg.j($CLJS.N,$CLJS.nm(function(k,l){$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);l=$CLJS.O(l);l=$CLJS.J.g(l,$CLJS.KE);return new $CLJS.P(null,2,5,$CLJS.Q,[k,l],null)}),b);b=$CLJS.eg.j($CLJS.N,$CLJS.nm(function(k,l){$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);l=$CLJS.O(l);
l=$CLJS.J.g(l,$CLJS.KE);return new $CLJS.P(null,2,5,$CLJS.Q,[l,k],null)}),b);var d=$CLJS.eZ,e=$CLJS.dZ;$CLJS.eZ=a;$CLJS.dZ=b;try{var f=$CLJS.WY.h(c);return $CLJS.rl($CLJS.E.g($CLJS.A(f),$CLJS.DM)?$CLJS.J.g(f,1):f)}finally{$CLJS.dZ=e,$CLJS.eZ=d}},extract_template_tags:M9,engine:function(a){return $CLJS.gh($CLJS.iva.l($CLJS.H([a])))},field_id:function(a){return $CLJS.wua.l($CLJS.H([a]))},filter_operator:function(a,b,c){return $CLJS.Iua.l($CLJS.H([a,b,c]))},binning:function(a){return $CLJS.Zta.l($CLJS.H([a]))},
query:function(a,b,c){c=$CLJS.fZ.h((0,$CLJS.JY)($CLJS.e2($CLJS.R7(c),$CLJS.qj,$CLJS.QF)));var d=$CLJS.RA($CLJS.Jy);$CLJS.n($CLJS.QA("metabase.lib.js",d))&&$CLJS.PA("metabase.lib.js",d,$CLJS.CW("query map: %s",$CLJS.H([$CLJS.Mh.l($CLJS.H([c]))])),null);return $CLJS.uva.l($CLJS.H([E9(a,b),c]))},columns_group_columns:function(a){return $CLJS.we($CLJS.dua.l($CLJS.H([a])))},join_fields:function(a){a=$CLJS.Tua.l($CLJS.H([a]));return a instanceof $CLJS.M?$CLJS.eB(a):$CLJS.we(a)},aggregate:function(a,b,c){return $CLJS.Vta.l($CLJS.H([a,
b,$CLJS.Uz(c,$CLJS.H([$CLJS.Bi,!0]))]))},legacy_card_or_table_id:function(a){return $CLJS.xua.l($CLJS.H([a]))},join_conditions:function(a){return $CLJS.we($CLJS.Sua.l($CLJS.H([a])))},query_EQ_:R9,temporal_bucket:function(a){return $CLJS.Hva.l($CLJS.H([a]))},available_drill_thrus:function(a,b,c,d,e,f){var k=$CLJS.c9.l($CLJS.H([a,b])),l=$CLJS.eg.j($CLJS.N,$CLJS.nm(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.KE);return new $CLJS.P(null,2,5,$CLJS.Q,[v,x],null)}),
k);k=$CLJS.eg.j($CLJS.N,$CLJS.nm(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.KE);return new $CLJS.P(null,2,5,$CLJS.Q,[x,v],null)}),k);var m=$CLJS.eZ,t=$CLJS.dZ;$CLJS.eZ=l;$CLJS.dZ=k;try{var u=function(){var v=c.field_ref;return $CLJS.n(v)?J9(v):null}();return $CLJS.we($CLJS.hua.l($CLJS.H([a,b,$CLJS.nl.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.Wi,K9(u,$CLJS.z9.h?$CLJS.z9.h(c):$CLJS.z9.call(null,c)),$CLJS.g6,u,$CLJS.Hj,void 0===d?null:null==d?$CLJS.V2:d],null),$CLJS.n(e)?
new $CLJS.h(null,1,[$CLJS.f6,$CLJS.Yk.g(Awa,e)],null):null,$CLJS.n($CLJS.Fe(f))?new $CLJS.h(null,1,[$CLJS.QL,$CLJS.Yk.g(Bwa,f)],null):null]))])))}finally{$CLJS.dZ=t,$CLJS.eZ=m}},remove_clause:function(a,b,c){return $CLJS.xva.l($CLJS.H([a,b,$CLJS.e9.l($CLJS.H([$CLJS.Uz(c,$CLJS.H([$CLJS.Bi,!0]))]))]))},replace_clause:function(a,b,c,d){return $CLJS.Ava.l($CLJS.H([a,b,$CLJS.e9.l($CLJS.H([$CLJS.Uz(c,$CLJS.H([$CLJS.Bi,!0]))])),$CLJS.e9.l($CLJS.H([$CLJS.Uz(d,$CLJS.H([$CLJS.Bi,!0]))]))]))},drop_stage:function(a){return $CLJS.Dva.l($CLJS.H([a]))},
join_lhs_display_name:function(a,b,c,d){return $CLJS.Uua.l($CLJS.H([a,b,c,d]))},add_field:function(a,b,c){return $CLJS.vua.l($CLJS.H([a,b,c]))},pivot_types:function(a){return $CLJS.we($CLJS.kua.l($CLJS.H([a])))},native_extras:function(a){return $CLJS.rl($CLJS.lva.l($CLJS.H([a])))},available_temporal_buckets:Q9,with_binning:function(a,b){return $CLJS.$ta.l($CLJS.H([a,b]))},append_stage:function(a){return $CLJS.Cva.l($CLJS.H([a]))},join:function(a,b,c){return $CLJS.Mua.l($CLJS.H([a,b,c]))},aggregations:function(a,
b){return $CLJS.we($CLJS.c9.l($CLJS.H([a,b])))},drill_thru:V9,raw_native_query:function(a){return $CLJS.fva.l($CLJS.H([a]))},filterable_column_operators:function(a){return $CLJS.we($CLJS.Gua.l($CLJS.H([a])))},with_temporal_bucket:function(a,b){return $CLJS.Iva.l($CLJS.H([a,b]))},with_join_conditions:function(a,b){return $CLJS.ava.l($CLJS.H([a,$CLJS.Uz(b,$CLJS.H([$CLJS.Bi,!0]))]))},available_metrics:function(a){return $CLJS.we($CLJS.dva.l($CLJS.H([a])))},with_different_database:U9,with_fields:function(a,
b,c){return $CLJS.Cua.l($CLJS.H([a,b,c]))},is_column_metadata:function(a){return $CLJS.xd(a)&&$CLJS.E.g($CLJS.WK,$CLJS.Ij.h(a))},filter_args_display_name:function(a,b,c){return $CLJS.uua.l($CLJS.H([a,b,c]))},join_clause:function(a,b){return $CLJS.Nua.l($CLJS.H([a,b]))},aggregation_clause:S9,external_op:function(a){a=$CLJS.fua.l($CLJS.H([a]));var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.GE);var c=$CLJS.J.g(b,$CLJS.jj);b=$CLJS.J.g(b,$CLJS.et);return{operator:a,options:$CLJS.rl(c),args:$CLJS.we(b)}},with_join_fields:function(a,
b){return $CLJS.Zua.l($CLJS.H([a,"string"===typeof b?$CLJS.zh.h(b):b]))},find_visible_column_for_legacy_ref:function(a,b,c){return $CLJS.Aua.l($CLJS.H([a,b,c]))},visible_columns:function(a,b){return $CLJS.e0($CLJS.zh.g("visible-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){var c=$CLJS.NW(a,b),d=$CLJS.R0.j(a,b,c);c=$CLJS.P0.j(a,b,c);return $CLJS.we($CLJS.F3(a,b,d,c))})},available_binning_strategies:P9,find_column_indexes_from_legacy_refs:function(a,b,c,d){var e=$CLJS.iG.h($CLJS.NW(a,b)),
f=$CLJS.eg.j($CLJS.N,$CLJS.nm(function(u,v){$CLJS.I(v,0,null);v=$CLJS.I(v,1,null);v=$CLJS.O(v);v=$CLJS.J.g(v,$CLJS.KE);return new $CLJS.P(null,2,5,$CLJS.Q,[u,v],null)}),e);e=$CLJS.eg.j($CLJS.N,$CLJS.nm(function(u,v){$CLJS.I(v,0,null);v=$CLJS.I(v,1,null);v=$CLJS.O(v);v=$CLJS.J.g(v,$CLJS.KE);return new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null)}),e);var k=$CLJS.eZ,l=$CLJS.dZ;$CLJS.eZ=f;$CLJS.dZ=e;try{var m=$CLJS.Yk.g(zwa,c),t=$CLJS.hf.g(J9,d);return $CLJS.we(twa(a,b,t,m))}finally{$CLJS.dZ=l,$CLJS.eZ=k}},
expression:function(a,b,c,d){return $CLJS.nua.l($CLJS.H([a,b,c,d]))},describe_relative_datetime:function(a,b){a="string"===typeof a?$CLJS.zh.h(a):a;b="string"===typeof b?$CLJS.zh.h(b):b;return $CLJS.Gva.l($CLJS.H([a,b]))},order_bys:function(a,b){return $CLJS.we($CLJS.tva.l($CLJS.H([a,b])))},display_info:function(a,b,c){return $CLJS.e0($CLJS.zh.g("display-info-outer",["stage-",$CLJS.p.h(b)].join("")),c,function(d){return H9($CLJS.bva.l($CLJS.H([$CLJS.Y3(a,b),b,d])))})},fieldable_columns:function(a,
b){return $CLJS.e0($CLJS.zh.g("fieldable-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){return $CLJS.we($CLJS.yua.l($CLJS.H([a,b])))})},template_tags:function(a){return $CLJS.rl($CLJS.hva.l($CLJS.H([a])))},selected_aggregation_operators:function(a,b){return $CLJS.we($CLJS.Yta.l($CLJS.H([$CLJS.y(a),b])))},order_by_clause:O9,filterable_columns:function(a,b){return $CLJS.e0($CLJS.zh.g("filterable-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){return $CLJS.we($CLJS.Fua.l($CLJS.H([a,
b])))})},join_condition_rhs_columns:function(a,b,c,d,e){return $CLJS.we($CLJS.Qua.l($CLJS.H([a,b,c,d,e])))},join_condition_update_temporal_bucketing:function(a,b,c,d){return $CLJS.Rua.l($CLJS.H([a,b,c,d]))},filters:function(a,b){return $CLJS.we($CLJS.Eua.l($CLJS.H([a,b])))},join_condition_lhs_columns:function(a,b,c,d,e){return $CLJS.we($CLJS.Oua.l($CLJS.H([a,b,c,d,e])))},table_or_card_metadata:function(a,b){return $CLJS.I1(a,b)},with_expression_name:function(a,b){return $CLJS.rua.l($CLJS.H([a,b]))},
orderable_columns:function(a,b){return $CLJS.e0($CLJS.zh.g("orderable-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){return $CLJS.we($CLJS.N7.g(a,b))})},find_filter_for_legacy_filter:function(a,b,c){return $CLJS.Jua.l($CLJS.H([a,b,$CLJS.Uz(c,$CLJS.H([$CLJS.Bi,!0]))]))},suggestedName:function(a){return $CLJS.cva.l($CLJS.H([a]))},find_column_for_legacy_ref:function(a,b,c,d){return $CLJS.lua.l($CLJS.H([a,b,c,d]))},has_write_permission:function(a){return $CLJS.ova.l($CLJS.H([a]))},metadataProvider:E9,
filter_clause:T9,fields:function(a,b){return $CLJS.we($CLJS.zua.l($CLJS.H([a,b])))},available_aggregation_operators:function(a,b){return $CLJS.we($CLJS.Xta.l($CLJS.H([a,b])))},expression_name:function(a){return $CLJS.oua.l($CLJS.H([a]))},breakouts:function(a,b){return $CLJS.we($CLJS.cua.l($CLJS.H([a,b])))},joinable_columns:function(a,b,c){return $CLJS.we($CLJS.Wua.l($CLJS.H([a,b,c])))},group_columns:function(a){return $CLJS.we($CLJS.eua.l($CLJS.H([a])))},describe_temporal_unit:function(a,b){b="string"===
typeof b?$CLJS.zh.h(b):b;return $CLJS.Eva.l($CLJS.H([a,b]))},joins:function(a,b){return $CLJS.we($CLJS.Xua.l($CLJS.H([a,b])))},native_query:function(a,b,c){return $CLJS.eva.l($CLJS.H([E9(a,b),c]))},available_join_strategies:function(a,b){return $CLJS.we($CLJS.Lua.l($CLJS.H([a,b])))},legacy_query:function(a){return $CLJS.rl.l(N9($CLJS.WY.h(a)),$CLJS.H([$CLJS.Bk,$CLJS.eB]))},stage_count:function(a){return $CLJS.vva.l($CLJS.H([a]))},expression_clause:function(a,b,c){return $CLJS.sua.l($CLJS.H([$CLJS.zh.h(a),
b,$CLJS.Uz(c,$CLJS.H([$CLJS.Bi,!0]))]))},find_matching_column:function(a,b,c,d){return $CLJS.mua.l($CLJS.H([a,b,c,d]))},with_native_extras:function(a,b){return $CLJS.mva.l($CLJS.H([a,$CLJS.Uz(b,$CLJS.H([$CLJS.Bi,!0]))]))},join_strategy:function(a){return $CLJS.Vua.l($CLJS.H([a]))},picker_info:function(a,b){var c=$CLJS.Ij.h(b);switch(c instanceof $CLJS.M?c.T:null){case "metadata/table":return{databaseId:$CLJS.cQ.h(a),tableId:$CLJS.cj.h(b)};case "metadata/card":return{databaseId:$CLJS.cQ.h(a),tableId:["card__",
$CLJS.p.h($CLJS.cj.h(b))].join(""),cardId:$CLJS.cj.h(b),isModel:$CLJS.JZ.h(b)};default:return a=$CLJS.RA($CLJS.My),$CLJS.n($CLJS.QA("metabase.lib.js",a))&&$CLJS.PA("metabase.lib.js",a,$CLJS.Iw.l($CLJS.H(["Cannot provide picker-info for",$CLJS.Ij.h(b)])),null),null}},expression_parts:function(a,b,c){a=$CLJS.tua.l($CLJS.H([a,b,c]));return $CLJS.CV(function(d){if($CLJS.xd(d)&&$CLJS.E.g($CLJS.E8,$CLJS.Ij.h(d))){var e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.GE);var f=$CLJS.J.g(e,$CLJS.jj);e=$CLJS.J.g(e,$CLJS.et);
return{operator:$CLJS.gh(d),options:$CLJS.rl($CLJS.lm(f,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BG,$CLJS.mk],null))),args:$CLJS.we($CLJS.hf.g(function(k){return k instanceof $CLJS.M?$CLJS.eB(k):k},e))}}return d},a)},expression_clause_for_legacy_expression:function(a,b,c){b=$CLJS.c9.l($CLJS.H([a,b]));a=$CLJS.eg.j($CLJS.N,$CLJS.nm(function(l,m){$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);m=$CLJS.O(m);m=$CLJS.J.g(m,$CLJS.KE);return new $CLJS.P(null,2,5,$CLJS.Q,[l,m],null)}),b);b=$CLJS.eg.j($CLJS.N,$CLJS.nm(function(l,
m){$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);m=$CLJS.O(m);m=$CLJS.J.g(m,$CLJS.KE);return new $CLJS.P(null,2,5,$CLJS.Q,[m,l],null)}),b);var d=$CLJS.eZ,e=$CLJS.dZ;$CLJS.eZ=a;$CLJS.dZ=b;try{var f=$CLJS.Uz(c,$CLJS.H([$CLJS.Bi,!0])),k=$CLJS.A($CLJS.KY(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QF,$CLJS.iG],null),new $CLJS.P(null,1,5,$CLJS.Q,[f],null)));return $CLJS.fZ.h(k)}finally{$CLJS.dZ=e,$CLJS.eZ=d}},format_relative_date_range:function(a,b,c,d,e){b=$CLJS.zh.h(b);d=null==d?null:$CLJS.zh.h(d);e=$CLJS.Uz(e,$CLJS.H([$CLJS.Bi,
!0]));return $CLJS.I2((0,$CLJS.WI)(),a,b,c,d,e)},aggregation_operator_columns:function(a){return $CLJS.we($CLJS.Wta.l($CLJS.H([a])))},find_filterable_column_for_legacy_ref:function(a,b,c){return $CLJS.Kua.l($CLJS.H([a,b,c]))},available_segments:function(a,b){return $CLJS.we($CLJS.Bva.l($CLJS.H([a,b])))},join_condition_operators:function(a,b,c,d){return $CLJS.we($CLJS.Pua.l($CLJS.H([a,b,c,d])))},expressionable_columns:function(a,b,c){return $CLJS.e0($CLJS.zh.g("expressionable-columns",["stage-",$CLJS.p.h(b),
"-",$CLJS.p.h(c)].join("")),a,function(){return $CLJS.we($CLJS.qua.l($CLJS.H([a,b,c])))})},filter:function(a,b,c){return $CLJS.Dua.l($CLJS.H([a,b,$CLJS.Uz(c,$CLJS.H([$CLJS.Bi,!0]))]))},change_direction:function(a,b){return $CLJS.qva.l($CLJS.H([a,b]))},pivot_columns_for_type:function(a,b){return $CLJS.jua.l($CLJS.H([a,b]))},filter_drill_details:function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.QF);var c=$CLJS.J.g(b,$CLJS.Wi),d=$CLJS.J.g(b,$CLJS.NL);b=$CLJS.J.g(b,$CLJS.Hj);return{column:c,query:a,stageNumber:d,
value:$CLJS.E.g(b,$CLJS.V2)?null:b}},legacy_field_ref:function(a){return $CLJS.rl($CLJS.Zk.v($CLJS.WY.h($CLJS.f9.l($CLJS.H([a]))),2,$CLJS.Jt,function(b){return $CLJS.me(b)?$CLJS.eB(b):b}))},remove_field:function(a,b,c){return $CLJS.Bua.l($CLJS.H([a,b,c]))},with_template_tags:function(a,b){return $CLJS.jva.l($CLJS.H([a,D9(b)]))},expressions:function(a,b){return $CLJS.we($CLJS.pua.l($CLJS.H([a,b])))},suggested_join_conditions:function(a,b,c){return $CLJS.we($CLJS.Yua.l($CLJS.H([a,b,c])))},with_join_strategy:function(a,
b){return $CLJS.$ua.l($CLJS.H([a,b]))},order_by:function(a,b,c,d){return $CLJS.rva.l($CLJS.H([a,b,c,$CLJS.zh.h(d)]))},describe_temporal_interval:function(a,b){a="string"===typeof a?$CLJS.zh.h(a):a;b="string"===typeof b?$CLJS.zh.h(b):b;return $CLJS.Fva.l($CLJS.H([a,b]))},breakout:function(a,b,c){return $CLJS.aua.l($CLJS.H([a,b,$CLJS.f9.l($CLJS.H([c]))]))},database_id:function(a){return $CLJS.gua.l($CLJS.H([a]))},required_native_extras:function(a,b){return $CLJS.we($CLJS.hf.g($CLJS.eB,$CLJS.kva.l($CLJS.H([E9(a,
b)]))))},remove_join:function(a,b,c){return $CLJS.yva.l($CLJS.H([a,b,c]))},joined_thing:function(a,b){return $CLJS.S3(a,b)},with_native_query:function(a,b){return $CLJS.gva.l($CLJS.H([a,b]))},breakoutable_columns:function(a,b){return $CLJS.e0($CLJS.zh.g("breakoutable-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){return $CLJS.we($CLJS.bua.l($CLJS.H([a,b])))})},rename_join:function(a,b,c,d){return $CLJS.zva.l($CLJS.H([a,b,c,d]))},with_different_table:function(a,b){return $CLJS.wva.l($CLJS.H([a,
b]))}};