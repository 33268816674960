var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.metadata.js");require("./metabase.lib.query.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.humanization.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var Tsa,Usa,Wsa,Xsa,Ysa,Zsa;Tsa=function(a,b){return $CLJS.NA(function(c){return function(d,e,f){return $CLJS.n(a.h?a.h(e):a.call(null,e))?c.j?c.j(d,e,f):c.call(null,d,e,f):d}},b)};Usa=function(a,b){return $CLJS.gc($CLJS.eb(function(c,d){return $CLJS.hg.j(c,a.h?a.h(d):a.call(null,d),d)},$CLJS.ec($CLJS.N),b))};Wsa=function(a){return $CLJS.eg.j($CLJS.bh,$CLJS.Wk.g($CLJS.sE(function(b){return $CLJS.aX(b,a)}),$CLJS.hf.h($CLJS.hd)),Vsa)};
Xsa=function(a){return new $CLJS.h(null,3,[$CLJS.qj,$CLJS.DB,$CLJS.T,a,$CLJS.cj,$CLJS.p.h($CLJS.UE())],null)};
Ysa=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.T);return $CLJS.nl.l($CLJS.H([a,function(){var c=$CLJS.nh(/^#(\d+)(-[a-z0-9-]*)?$/,b);$CLJS.n(c)?($CLJS.I(c,0,null),c=$CLJS.I(c,1,null),c=$CLJS.BW(c)):c=null;return $CLJS.n(c)?new $CLJS.h(null,2,[$CLJS.qj,$CLJS.EN,$CLJS.lR,c],null):null}(),function(){var c=0==b.lastIndexOf("snippet:",0)?(0,$CLJS.ma)(b.substring(8)):null;return $CLJS.n(c)?new $CLJS.h(null,2,[$CLJS.qj,$CLJS.PM,$CLJS.BM,c],null):null}(),$CLJS.n($CLJS.yE.h(a))?null:new $CLJS.h(null,
1,[$CLJS.yE,$CLJS.H1.g($CLJS.EB,b)],null)]))};$CLJS.J8=function(a){a=$CLJS.A1($CLJS.$_(a));return $CLJS.n($CLJS.IA(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nA,Zsa],null)))?$CLJS.be.g($CLJS.bh,$CLJS.uO):$CLJS.bh};$CLJS.K8=function(a,b){var c=$CLJS.J8(a);return(0,$CLJS.YW)(a,0,function(d){var e=$CLJS.nu.g($sa,c);d=$CLJS.Se.j($CLJS.Ok,d,e);d=$CLJS.nl.l($CLJS.H([d,$CLJS.lm(b,c)]));$CLJS.nu.g(c,$CLJS.fh($CLJS.Xg(b)));$CLJS.NW(a,0);return d})};$CLJS.L8=function(a){return $CLJS.Fe($CLJS.lm($CLJS.NW(a,0),$CLJS.J8(a)))};
$CLJS.M8=function(a){return $CLJS.sQ.h($CLJS.NW(a,0))};Zsa=new $CLJS.M(null,"native-requires-specified-collection","native-requires-specified-collection",1239149925);$CLJS.ata=new $CLJS.M(null,"write","write",-1857649168);$CLJS.bta=new $CLJS.M(null,"native-permissions","native-permissions",1277672164);var Vsa,$sa;Vsa=new $CLJS.P(null,3,5,$CLJS.Q,[/\{\{\s*([A-Za-z0-9_\.]+)\s*\}\}/,/\{\{\s*(snippet:\s*[^}]+)\s*\}\}/,/\{\{\s*(#([0-9]*)(-[a-z0-9-]*)?)\s*\}\}/],null);
$CLJS.N8=function(){function a(d,e){d=$CLJS.Fe(Wsa(d));var f=$CLJS.Fe($CLJS.fh($CLJS.Xg(e)));if($CLJS.n($CLJS.n(d)?d:f)){var k=$CLJS.nu.g(d,f);d=$CLJS.nu.g(f,d);if($CLJS.E.l(1,$CLJS.D(k),$CLJS.H([$CLJS.D(d)]))){d=$CLJS.A(d);k=$CLJS.A(k);f=$CLJS.J.g(e,d);var l=$CLJS.E.g($CLJS.yE.h(f),$CLJS.H1.g($CLJS.EB,d))?$CLJS.H1.g($CLJS.EB,k):$CLJS.yE.h(f);f=$CLJS.R.l($CLJS.Ok.l(f,$CLJS.BM,$CLJS.H([$CLJS.lR,$CLJS.bQ])),$CLJS.yE,l,$CLJS.H([$CLJS.T,k]));e=$CLJS.R.j($CLJS.Ok.g(e,d),k,f)}else e=$CLJS.nl.l($CLJS.H([Tsa($CLJS.Te(d),
e),Usa($CLJS.T,$CLJS.hf.g(Xsa,k))]));e=$CLJS.Jt(e,Ysa)}else e=$CLJS.N;return e}function b(d){return $CLJS.N8.g?$CLJS.N8.g(d,null):$CLJS.N8.call(null,d,null)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$sa=new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.uO,null],null),null);
$CLJS.X(new $CLJS.M("metabase.lib.native","native-extras","metabase.lib.native/native-extras",1125545077),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uO,new $CLJS.h(null,1,[$CLJS.Us,!0],null),$CLJS.DE],null)],null));
$CLJS.O8=function(){function a(d,e,f,k){var l=$CLJS.N8.h(e);return $CLJS.K8($CLJS.E1.g(d,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.Ij,$CLJS.jW,$CLJS.wV,f,$CLJS.PQ,l,$CLJS.sQ,e],null)],null)),k)}function b(d,e){return $CLJS.O8.v?$CLJS.O8.v(d,e,null,null):$CLJS.O8.call(null,d,e,null,null)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 2:return b.call(this,d,e);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.v=a;return c}();
$CLJS.P8=function(){function a(d,e,f){$CLJS.NW(d,0);return $CLJS.K8($CLJS.E1.g(e,$CLJS.zL.h(d)),f)}function b(d,e){return $CLJS.P8.j?$CLJS.P8.j(d,e,null):$CLJS.P8.call(null,d,e,null)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.D1.m(null,$CLJS.jW,function(a){return $CLJS.x1($CLJS.J8(a),$CLJS.fh($CLJS.Xg($CLJS.L8(a))))&&!$CLJS.MA($CLJS.M8(a))});